import React, {useEffect, useState} from 'react';
import { MaterialTypeService} from "../../api/ApiService";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {TableCell} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {Link} from "react-router-dom";

const materialTypeService = new MaterialTypeService();

export default function MaterialTypesPage() {
    const [materialTypes, setMaterialTypes] = useState([]);

    useEffect(() => {
        const loadMaterialTypes = async () => {
            let response = await materialTypeService.get();
            setMaterialTypes(response);
        }
        loadMaterialTypes();
    }, []);

    return (
        <Paper>
            <Button component={Link} style={{float: 'right'}}  variant="contained" color="primary" to={"/create"}>Create</Button>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {materialTypes.map(mt => (
                        <TableRow key={mt.id}>
                            <TableCell>{mt.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}