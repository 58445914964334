import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import {createTheme, ThemeProvider} from "@mui/material";
import { StyledEngineProvider } from '@mui/material/styles';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider} from "@mui/lab";
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

Sentry.init({
    dsn: "https://c27cdcb365114fbdad2760e57a21d485@o1120382.ingest.sentry.io/6156220",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

LogRocket.init('by5azo/fabcore');
setupLogRocketReact(LogRocket);



const theme = createTheme({
    typography: {
        fontFamily: [
            'Ubuntu',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        primary: {
            main: '#9142DB',
        }
    },
    components: {
        MuiTableCell: {
            defaultProps: {
                
            },
            styleOverrides: {
                root: {
                    padding: '8px'
                }
            }
        },
        /*MuiFilledInput: {
            defaultProps: {
                disableUnderline: true
            },
            styleOverrides: {
                root: {
                    background: grey[100],
                    borderRadius: '8px',
                    borderColor: grey[100],
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    "&:hover": {
                        borderColor: grey[200],
                        background: grey[200]
                    },
                    "&.Mui-focused": {
                        borderColor: '#7c3aed',
                        boxShadow: '0 0 0 0.25rem ' + purple[100]
                    },
                },
                
                
            }
        },*/
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontWeight: 400
                }
            }
        }
        // MuiFormLabel: {
        //     styleOverrides: {
        //         root: {
        //             fontSize: '20px'
        //         }
        //     }
        // },
        // MuiInputLabel: {
        //     styleOverrides: {
        //         root: {
        //             fontSize: '20px'
        //         }
        //     }
        // }
    }
});

ReactDOM.render(
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <BrowserRouter basename={baseUrl}>
          <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
          </StyledEngineProvider>
      </BrowserRouter>
    </LocalizationProvider>,
  rootElement);