import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {ProjectFileDirectoriesService } from "../../api/ApiService";
import TextField from "@mui/material/TextField";

const directoryService = new ProjectFileDirectoriesService();

export default function CreateDirectoryDialog({isOpen, projectId, directoryId, onClose}) 
{
    let [loading, setLoading] = useState(false);
    let [directoryName, setDirectoryName] = useState('');
    const handleCancel = () => {
        onClose(false);
    };
    
    const handleConfirm = async () => {
        setLoading(true);
        
        await directoryService.createDirectory(projectId, directoryId, directoryName);
        
        setLoading(false);
        onClose(true);
    };
    
    return (
        <Dialog open={isOpen}>
            <DialogTitle>Create New Directory</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    id="directoryName"
                    label="Directory Name"
                    type="text"
                    fullWidth
                    onChange={(e) => setDirectoryName(e.target.value)}
                    value={directoryName}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant={"outlined"}>Cancel</Button>
                <Button onClick={handleConfirm} variant={"contained"} color={"primary"} autoFocus disabled={loading}>Create</Button>
            </DialogActions>
        </Dialog>
    )
}