import React, { useEffect, useState, useCallback } from 'react';
import Table from '@mui/material/Table'
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import { Link as RouterLink } from 'react-router-dom';

type StockLocation = {
    id: string,
    code: string,
    parentId: string | null,
    children: StockLocation[]
}

type StockLocationsState = {
    error: { message: string },
    isLoaded: boolean,
    stockLocations: StockLocation[],
    deleteStockLocationDialogOpen: boolean,
    addStockLocationDialogOpen: boolean,
    editStockLocationDialogOpen: boolean,
    addStockLocationId: string | null,
    deleteStockLocationId: string | null,
    editStockLocationId: string | null,
    addStockLocationFullCode: string | null,
    deleteStockLocationFullCode: string | null,
    editStockLocationParentCode: string | null,
    editStockLocationCode: string | null
    
}

function AddStockLocationFormDialog(props: any) {
    const [locations, setLocations] = React.useState('');
    const handleCancel = () => {
        props.onClose();
    };
    
    const handleAdd = () => {
        props.onAdd(props.locationId, locations);
        props.onClose();
    };

    const updateLocationCodes = (event: any) => {
        setLocations(event.target.value);
    } 

    return (
        <div>
            <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Stock Locations {props.fullCode ? 'to ' + props.fullCode : ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To add more than one location at a time you can seperate each location with a comma for example: "A,B,C,D". 
                        You may also generate a range of locations by specifying a starting and ending number/character seperated by a dash, 
                        for example: "A-E", will generate A,B,C,D and E locations.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="locationCode"
                        label="Location Code"
                        type="text"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{props.fullCode ? props.fullCode + '-' : ''}</InputAdornment>,
                        }}
                        onChange={updateLocationCodes}
                        value={locations}
                    /> 
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function DeleteStockLocationFormDialog(props: any) {
    const handleCancel = () => {
        props.onClose();
    };

    const handleDelete = () => {
        props.onDelete(props.locationId);
        props.onClose();
    };

    return (
        <div>
            <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Stock Location {props.fullCode}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {props.fullCode}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function EditStockLocationFormDialog(props: any) {
    const [code, setCode] = React.useState('');
    useEffect(() => {
        setCode(props.code)
    }, [props.code]);
    const handleCancel = () => {
        props.onClose();
    };

    const handleEdit = () => {
        props.onEdit(props.locationId, code);
        props.onClose();
    };
 
    const updateLocationCodes = (event: any) => {
        setCode(event.target.value);
    }
    return (
        <div>
            <Dialog open={props.open} onClose={handleCancel} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Edit Stock Locations {props.fullCode}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Rename stock location to {code}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        id="locationCode"
                        label="Location Code"
                        type="text"
                        fullWidth
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{props.parentCode + '-'}</InputAdornment>,
                        }}
                        onChange={updateLocationCodes}
                        value={code}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEdit} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

function StockLocationRow(props: { item: StockLocation , parentCode: string | null, depth: number, onDeleteClick: any, onAddClick: any, onEditClick: any}) {
    
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    
    let handleClose = () => {
        setAnchorEl(null);
    };
    
    let handleDeleteClick = () => {
        handleClose();
        props.onDeleteClick(props.item.id, fullCode);
    };

    let handleAddClick = () => {
        handleClose();
        props.onAddClick(props.item.id, fullCode);
    };

    let handleEditClick = () => {
        handleClose();
        props.onEditClick(props.item.id, props.parentCode, props.item.code);
    };
    let fullCode = props.item.code;
    if(props.parentCode != null) { fullCode = props.parentCode + '-' + props.item.code}
    return (
        <>
        <TableRow hover>
            <TableCell style={{paddingLeft: 16+props.depth*16}}><Typography>{fullCode}</Typography>
            </TableCell>
            <TableCell align={"right"}>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} size={"small"}>
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)} 
                    onClose={handleClose}
                >
                    <MenuItem component={RouterLink} to={"/stock?stockLocationId=" + props.item.id}>View Stock Here</MenuItem>
                    <MenuItem onClick={handleAddClick}>Add More Locations</MenuItem>
                    <MenuItem onClick={handleEditClick}>Edit</MenuItem>
                    <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
                </Menu>
            </TableCell>
        </TableRow>
        {props.item.children.map((item: StockLocation) => (
        <StockLocationRow key={item.id} item={item} parentCode={fullCode} depth={props.depth+1} onDeleteClick={props.onDeleteClick} onAddClick={props.onAddClick} onEditClick={props.onEditClick}/>))}
        </>
    )
}

export default function StockLocations()
{
    let initialState: StockLocationsState = {
        error: { message: ''},
        isLoaded: false,
        stockLocations: [],
        deleteStockLocationDialogOpen: false,
        addStockLocationDialogOpen: false,
        editStockLocationDialogOpen: false,
        deleteStockLocationId: null,
        addStockLocationId: null,
        editStockLocationId: null,
        deleteStockLocationFullCode: null,
        addStockLocationFullCode: null,
        editStockLocationParentCode: null,
        editStockLocationCode: null
    };

    let [state, setState] = useState(initialState);
    
    

    const updateStockLocations = useCallback(() => {
        const getStockLocationChildren = (stockLocations: StockLocation[], parentId: string | null) => {
            return stockLocations.filter(i => i.parentId === parentId).map(i => {
                i.children = getStockLocationChildren(stockLocations, i.id);
                return i;
            }).sort((a, b) => a.code.localeCompare(b.code, 'en', {numeric: true } ));
        };
        
        const sortStockLocationResult = (stockLocations: StockLocation[]) : StockLocation[] => {
            return getStockLocationChildren(stockLocations, null);
        }

        fetch(`/api/stock-locations`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(s => {
                        return {
                            ...s,
                            isLoaded: true,
                            stockLocations: sortStockLocationResult(result)
                        }
                    });
                },
                (error) => {
                }
            )
    }, []);

    useEffect(() => {
        updateStockLocations();
    }, [updateStockLocations]);

    const handleDelete = (itemId: string, fullCode: string) => {
        setState(s => ({ ...s, deleteStockLocationDialogOpen: true, deleteStockLocationId: itemId, deleteStockLocationFullCode: fullCode }));
    };

    const handleAdd = (itemId: string | null, fullCode: string) => {
        setState(s => ({ ...s, addStockLocationDialogOpen: true, addStockLocationId: itemId, addStockLocationFullCode: fullCode }));
    };

    const handleEdit = (itemId: string, parentCode: string, code: string) => {
        setState(s => ({ ...s, editStockLocationDialogOpen: true, editStockLocationId: itemId, editStockLocationParentCode: parentCode, editStockLocationCode: code }));
    };

    const addStockLocations = (id: string, locations: string) => {
        fetch(`/api/stock-locations`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({parentId: id, code: locations})
        }).then( () => {
            setState(s => ({
                ...s,
                isLoaded: false,
                stockLocations: []
            }));
            updateStockLocations();
        })
    };

    const deleteStockLocation = (id: string) => {
        fetch(`/api/stock-locations/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        }).then( (result) => {
            setState(s => ({
                ...s,
                isLoaded: false,
                stockLocations: []
            }));
            updateStockLocations();
        })
    };

    const editStockLocation = (id: string, code: string) => {
        fetch(`/api/stock-locations/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({id: id, code: code})
        }).then( () => {
            setState(s => ({
                ...s,
                isLoaded: false,
                stockLocations: []
            }));
            updateStockLocations();
        })
    };

    const onDeleteStockDialogClose = () => {
        setState(s => ({ ...s, deleteStockLocationDialogOpen: false, deleteStockLocationId: null, deleteStockLocationFullCode: null }));
    };

    const onAddStockDialogClose = () => {
        setState(s => ({ ...s, addStockLocationDialogOpen: false, addStockLocationId: null, addStockLocationFullCode: null }));
    };

    const onEditStockDialogClose = () => {
        setState(s => ({ ...s, editStockLocationDialogOpen: false, editStockLocationId: null, editStockLocationCode: null, editStockLocationParentCode: null }));
    };

    const { error, isLoaded, stockLocations } = state;
    if (error.message.length > 0) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <Grid container>
                <Grid item xs={12} container direction={"column"}  alignItems={"flex-end"}  style={{'paddingTop': 16}}>
                    <Grid item xs={12}>
                        <Button onClick={() => handleAdd(null, "")} variant={"contained"} color={"primary"}>Create Stock Location</Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <TableCell><Typography variant={"button"}>Location Code</Typography></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {stockLocations.length === 0 &&
                            <TableRow><TableCell colSpan={2}>No stock locations found</TableCell></TableRow>}
                            {stockLocations.map((item: StockLocation) => (
                                <StockLocationRow key={item.id} item={item} parentCode={null} depth={0} onDeleteClick={handleDelete} onAddClick={handleAdd} onEditClick={handleEdit}/>
                            ))}
                            </TableBody>
                        </Table>
                    </Grid>
                <DeleteStockLocationFormDialog open={state.deleteStockLocationDialogOpen} fullCode={state.deleteStockLocationFullCode} locationId={state.deleteStockLocationId} onDelete={deleteStockLocation} onClose={onDeleteStockDialogClose}/>
                <AddStockLocationFormDialog open={state.addStockLocationDialogOpen} fullCode={state.addStockLocationFullCode} locationId={state.addStockLocationId} onAdd={addStockLocations} onClose={onAddStockDialogClose}/>
                <EditStockLocationFormDialog open={state.editStockLocationDialogOpen} parentCode={state.editStockLocationParentCode} locationId={state.editStockLocationId} code={state.editStockLocationCode} onEdit={editStockLocation} onClose={onEditStockDialogClose}/>
                </Grid>
            
        );
    }
}