import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {MoveFilesRequest, ProjectFilesService} from "../../api/ApiService";
import ProjectDirectorySelector from "../../components/projects/ProjectDirectorySelector";

const projectFileService = new ProjectFilesService();

export default function MoveDialog({isOpen, projectId, directories, files, onClose}) 
{
    let [loading, setLoading] = useState(false);
    let [directory, setDirectory] = useState('');
    const handleCancel = () => {
        onClose(false);
    };
    
    const handleConfirm = async () => {
        setLoading(true);
        let directoryIds = directories.map(d => d.id);
        let fileIds = files.map(f => f.id);
        await projectFileService.move(projectId, new MoveFilesRequest({ directories: directoryIds, files: fileIds, projectId: projectId, targetDirectory: directory}));
        //await directoryService.createDirectory(projectId, directoryId, directoryName);
        
        setLoading(false);
        onClose(true);
    };
    
    const handleDirectoryChange = (directoryId) => {
        setDirectory(directoryId);
    }
    
    return (
        <Dialog open={isOpen}>
            <DialogTitle>Move Selected To:</DialogTitle>
            <DialogContent>
                <ProjectDirectorySelector projectId={projectId} selectedDirectoryId={directory} onSelectedDirectoryChanged={handleDirectoryChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant={"outlined"}>Cancel</Button>
                <Button onClick={handleConfirm} variant={"contained"} color={"primary"} autoFocus disabled={loading}>Move</Button>
            </DialogActions>
        </Dialog>
    )
}