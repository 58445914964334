import React, {useEffect, useState} from 'react';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ProjectDirectorySelectorOption from "./ProjectDirectorySelectorOption";
import {ProjectFileDirectoriesService} from "../../api/ApiService";

const directoryService = new ProjectFileDirectoriesService();
export default function ProjectDirectorySelector({ projectId, selectedDirectoryId, onSelectedDirectoryChanged})
{
    let [directory, setDirectories] = useState([]);

    useEffect(() => {
        const loadData = async() => {
            let directoriesRequest = await directoryService.getDirectories(projectId);
            setDirectories(directoriesRequest.directories);
        };

        loadData();
    }, [projectId]);

    const topLevelDirectories = directory.filter(i => !i.parentId);

    const handleChange = (event) => {
        console.log(event.target);
        onSelectedDirectoryChanged(event.target.value);
    }
    
    return (
        <FormControl style={{width: '100%'}}>
            <InputLabel id="directory-selector-label">Directory</InputLabel>
            <Select
                native
                labelId="directory-selector-label"
                id="directory-selector"
                onChange={handleChange} value={selectedDirectoryId}
            >
                <option value="">/</option>
                {topLevelDirectories.map(item => (
                    <ProjectDirectorySelectorOption key={item.id} directories={directory} directory={item} depth={1}/>
                ))}
            </Select>
        </FormControl>
    );
}

