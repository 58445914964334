import React, {useEffect, useState} from 'react';
import {ProjectDefaultDirectoriesService} from "../../api/ApiService";
import {Chip, TableCell} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";

let pddService = new ProjectDefaultDirectoriesService();
export default function ProjectDirectories() {
    let [directories, setDirectories] = useState([]);
    useEffect(() => {
        const loadDirectories = async () => {
            let response = await pddService.getDefaultDirectories();
            setDirectories(response);
        }
        loadDirectories();
    }, []);

    return (
        <Table>
            <TableHead>
                <TableCell>
                    Directory Name
                </TableCell>
            </TableHead>
            <TableBody>
                {directories.map(d => (
                    <TableRow key={d.id}>
                        <TableCell>{d.name}&nbsp;
                            {d.isFabricationDrawings ? <Chip color="primary" size="small" label={'Fabrication Drawings'}/> : ''}    
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}