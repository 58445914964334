import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    mmStyle: {
        fontSize: '.8em'
    }
});

export default function DistanceMeasurement({ value }) {
    let styles = useStyles();
    return <span>{value} <span className={styles.mmStyle}>mm</span></span>
}