import React, {useState} from 'react';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {ProjectFileDirectoriesService, ProjectFilesService} from "../../api/ApiService";


const filesService = new ProjectFilesService();
const directoryService = new ProjectFileDirectoriesService();

export default function DeleteConfirmationDialog({isOpen, projectId, files, directories, onClose}) 
{
    let [loading, setLoading] = useState(false);
    const handleCancel = () => {
        onClose(false);
    };
    
    const handleConfirm = async () => {
        setLoading(true);
        
        await Promise.all(directories.map(d => {
            return directoryService.deleteDirectory(d.id, projectId);
        }));
        
        await filesService.deleteFiles(projectId, { fileIds: files.map(i => i.id)});
        setLoading(false);
        onClose(true);
    };
    
    return (
        <Dialog open={isOpen}>
            <DialogTitle>Are you sure you want to delete these files?</DialogTitle>
            <DialogContent>You won't be able to recover these files if you proceed.</DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant={"outlined"}>Cancel</Button>
                <Button onClick={handleConfirm} variant={"contained"} color={"primary"} autoFocus disabled={loading}>Yes, delete them</Button>
            </DialogActions>
        </Dialog>
    )
}