import React from 'react';
import {Autocomplete} from "@mui/lab";
import {TextField} from "@mui/material";


export default function TakeOffMaterialCodeInput({materials, value, onValueChange}) {
    materials = materials.sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true})).map(i => {
        return { id: i.id, label: i.code};
    });

    let isOptionEqualToValue = (option, value) => {
        return option.label === value;
    }

    return (
        <Autocomplete
            fullWidth={true}
            autoSelect={true}
            freeSolo={true}
            sx={{margin: '0'}}
            options={materials}
            renderInput={(params) => <TextField  {...params} size={"small"} />}
            onChange={onValueChange} value={value}
            isOptionEqualToValue={isOptionEqualToValue}
        />
    )
}