import React, {useEffect, useState} from 'react';
import Paper from "@mui/material/Paper";
import {SurfaceFinishService} from "../../api/ApiService";
import {TableCell} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";

const surfaceFinishService = new SurfaceFinishService();

export default function SurfaceFinishes()
{
    let [surfaceFinishes, setSurfaceFinishes] = useState([]);
    
    useEffect(() => {
        let getSurfaceFinishes = async () => {
            let result = await surfaceFinishService.get();
            setSurfaceFinishes(result);
        }
        
        getSurfaceFinishes();
    }, []);
    
    return (
        <Paper>
            <h1>Surface Finishes</h1>
            <Button component={Link} to={"create"}>Create</Button>
            {surfaceFinishes.map(i => 
                <TableRow key={i.id}>
                    <TableCell>{i.code}</TableCell>
                    <TableCell>{i.name}</TableCell>
                    <TableCell><Button component={Link} to={i.id}>Edit</Button></TableCell>
                </TableRow>
            )}
        </Paper>
    )
}