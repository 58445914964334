import React, {useEffect, useState} from 'react';
import {TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {ProjectsService, ServiceConfiguration, User, UserService} from "../api/ApiService";

let projectService = new ProjectsService(new ServiceConfiguration());

function ProjectSelect({project, onChange, disabled, error, helperText}) {
    let [projects, setProjects] = useState([]);
    let [selectedProject, setSelectedProject] = useState(null);
    useEffect(() => {
        const getProjects = async () => {
            let projectsRequest = await projectService.getProjects();
            setProjects(projectsRequest.projects);
            
        }
        getProjects();
    }, []);
    
    useEffect(() => {
        if(typeof project === 'string') {
            let projectFound = projects.filter(i => i.id === project);
            if(projectFound && projectFound.length === 1) {
                setSelectedProject(projectFound[0]);
            }
        }
    }, [project, projects]);
    
    const onValueChange = (event, value) => {
        console.log(value);
        if(typeof project === 'string' && value != null) {
            onChange(value.id);
        } else {
            onChange(value);
        }
    }
    
    return (
        <Autocomplete
            fullWidth={true}
            options={projects}
            renderInput={(params) => {
                return <TextField {...params} label={"Project"} size="small" helperText={helperText} error={error}/> }}
            onChange={onValueChange} value={selectedProject}
            getOptionLabel={(option) => {
                return 'JB' + option.number + ' - ' + option.name;
            }}
            margin="dense"
        />
    )
}

export default ProjectSelect;