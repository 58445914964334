import React, {Fragment} from "react";
import Checkbox from "@mui/material/Checkbox";
import StatusBar from "../../components/parts/StatusBar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import styles from './AssemblyList.module.css';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function AssemblyListItem({assembly, onToggleStatusLine}) {
    return (
        <Fragment>
            <TableRow onClick={() => onToggleStatusLine(assembly.id)}>
                <TableCell  className={styles.select}>
                    <Checkbox
                        color="primary"
                    />
                </TableCell>
                    
                <TableCell>
                    {assembly.mark.toUpperCase()}
                </TableCell>
                <TableCell>
                    <img src="/pdf.svg" alt={assembly.mark.toUpperCase()} height="24px"/>
                </TableCell>
                <TableCell>{assembly.materialType && assembly.materialType.name}</TableCell>
                <TableCell>{assembly.material && assembly.material.code}</TableCell>
                <TableCell>
                    <StatusBar/>
                </TableCell>
                <TableCell>{assembly.qty}</TableCell>
                <TableCell>{assembly.length} mm</TableCell>
                <TableCell>{assembly.weight} kg</TableCell>
                <TableCell>
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" size={"small"}>
                        <MoreVertIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
        </Fragment>
)
}