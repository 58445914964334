import React, {useState} from 'react';
import ContactCreateDialog from "../components/ClientCreateDialog";

export default function Quotation() {
    let [client, setClient] = useState({
        id: ''
    })
    
    const handleClose = (value) => {
        console.log(value);
    }
    return (
        <div>
            {/*<ClientSelect />*/}
            <ContactCreateDialog open={true} onClose={handleClose} isCustomer={true} />
        </div>
    )
}