import React from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import PartsListItem from "./PartsListItem";
import TableContainer from "@mui/material/TableContainer";

export default function PartsLists({ parts, onToggleStatusLine }){
    return (
        <TableContainer>
            <Table size={"small"}>
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell>Part Mark</TableCell>
                        <TableCell>Files</TableCell>
                        <TableCell>Material</TableCell>
                        <TableCell>Profile</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Qty</TableCell>
                        <TableCell>Length</TableCell>
                        <TableCell>Weight</TableCell>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {parts.map((row) => (
                        <PartsListItem part={row} key={row.id} onToggleStatusLine={onToggleStatusLine}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
} 