import React from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {MenuItem, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";

export default function MaterialProfileList ( { onMaterialProfileChange, materialProfileIdSelected, materialProfileList}) {
    materialProfileList = materialProfileList.sort((a, b) => a.code.localeCompare(b.code, 'en', { numeric: true})).map(i => {
        return { id: i.id, label: i.code + ' ' + i.name};    
    });
    let materialProfileSelectedValue = '';

    if(materialProfileIdSelected) {
        materialProfileSelectedValue = materialProfileList.filter(i => i.id === materialProfileIdSelected)[0];
    }
    
    let handleChange = (event, newValue) => {
        //let materialProfile = materialProfileList.filter(i => i.label === event.target.)
        newValue = newValue == null ? '' : newValue.id;
        onMaterialProfileChange(newValue)
    }

    let isOptionEqualToValue = (option, value) => {
        return option.label === value;
    }
    
    return (
            <Autocomplete 
                label={"Profile Type"} 
                fullWidth={true} 
                sx={{marginTop: '24px'}} 
                options={materialProfileList}
                renderInput={(params) => <TextField {...params} label="Profile" />}
                onChange={handleChange} value={materialProfileSelectedValue.label}
                isOptionEqualToValue={isOptionEqualToValue}
            />
    )
}