import React, {useEffect, useState} from 'react';
import {WorkLogService} from "../../api/ApiService";
import {Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import {ChronoUnit, LocalTime} from "@js-joda/core";
import EditIcon from '@mui/icons-material/Edit';
import Button from "@mui/material/Button";
import { Link as RouterLink } from 'react-router-dom';

let workLogService = new WorkLogService();

function WorkLogListEntry({entry}) {
    return (
        <Paper style={{padding: 16}} variant={"outlined"}>
            
            <Stack spacing={2}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant={"h6"}>{entry.date.toDateString()}</Typography>
                    <Button variant={"text"} startIcon={<EditIcon/>} component={RouterLink} to={`worklog/${entry.id}/edit`}>Edit</Button>
                </div>
                <Divider/>
                <Box>
                    <Grid container>
                        <Grid item md={4} xs={12}>
            <Typography variant={"subtitle2"}>Users</Typography>
            <Typography variant={"body1"}>{entry.users.map(i => i.firstName + ' ' + i.lastName).join(', ')}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography variant={"subtitle2"}>Start Time</Typography>
                            <Typography variant={"body1"}>{entry.startTime ? LocalTime.parse(entry.startTime).truncatedTo(ChronoUnit.MINUTES).toString() : ''}</Typography>
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <Typography variant={"subtitle2"}>End Time</Typography>
                            <Typography variant={"body1"}>{entry.endTime ? LocalTime.parse(entry.endTime).truncatedTo(ChronoUnit.MINUTES).toString() : ''}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box><Typography variant={"subtitle2"}>Details</Typography> 
            <Typography style={{whiteSpace: "pre-wrap"}} variant={"body1"}>{entry.details}</Typography>
                </Box>
            <Divider/>
                <Box>
            <Typography variant={"subtitle2"}>Attachments</Typography>
            <Grid container spacing={1}>
                {entry.attachments.map( i => <WorkLogListEntryAttachmentItem key={i.id} file={i}/>)}
            </Grid>
                </Box>
            </Stack>
            
        </Paper>
    )
}

function WorkLogListEntryAttachmentItem({file}) {
    let isImage = false;
    if (file.fileName.match(/.(jpg|jpeg|png|gif)$/i)) {
        isImage = true;
    }

    return (
        <Grid item lg={3} md={6} sm={12}>
        <a href={file.url + (!isImage ? '/download' : '')} target="_blank" style={{display:'flexItem'}}>
            {isImage && <img style={{maxWidth: '100%', maxHeight: '150px'}} alt={file.fileName} src={file.url}/>}
            {!isImage && <InsertDriveFileIcon fontSize={"large"}/>}
            <p style={{marginLeft: '8px'}}>{file.fileName}</p>
        </a>
        </Grid>
    )
}

WorkLogListEntry.propTypes = {};
export default function WorkLogList({projectId}) {
    let [entries, setEntries] = useState([]);
    
    useEffect(() => {
        loadWorkListEntries();
        
        async function loadWorkListEntries() {
            let results = await workLogService.getWorkLogEntries(projectId);
            console.log(results);
            setEntries(results);
        }
    }, [projectId]);
    
    return (
            <Stack spacing={3}>
                {entries.length > 0 && entries.map(i => <WorkLogListEntry key={i.id} entry={i}/>)}
                {entries.length === 0 && <Typography variant={"body1"}>There are no diary entries yet. <RouterLink to={"worklog/new-entry"}>Click here to enter a new work diary entry.</RouterLink></Typography>}
            {}
            </Stack>
    )
}