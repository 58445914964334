import React from 'react';
import {Route, Routes} from "react-router-dom";
import {InputLabel} from "@mui/material";

export default function PartPage() {
    const part = {
        partMark: 'P107'
    }
    return (
        <div>
            <h1>{part.partMark}</h1>
            <div>
                <InputLabel>Hello</InputLabel>
                <input  type="text"/>
            </div>
            <div>
                <InputLabel>Hello</InputLabel>
                <input  type="text"/>
            </div>
            <Routes>
                <Route path={"/"} element={<PartDetailsTab/>}/>
                <Route path={"/files"} element={<PartFilesTab/>}/>
                <Route path={"/assemblies"} element={<PartAssembliesTab/>}/>
                <Route path={"/history"} element={<PartHistoryTab/>}/>
            </Routes>
        </div>
    )
}

function PartFilesTab() {
    return (
        <h2>Files</h2>
    )
}


function PartDetailsTab() {
    return (
        <h2>Details</h2>
    )
}


function PartAssembliesTab() {
    return (
        <h2>Assemblies</h2>
    )
}

function PartHistoryTab() {
    return (
        <h2>History</h2>
    )
}