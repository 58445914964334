import React, {useEffect, useState} from 'react';
import authService from "../auth/AuthorizeService";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Box, Breadcrumbs, Link, Stack, Tooltip} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import ProjectStatusSelect from "../components/projects/ProjectStatusSelect";
import {snackbarService} from "../components/ui/snackbar/SnackbarService";
import {useParams} from "react-router-dom";
import WorkLogList from "./worklog/WorkLogList";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {ProjectsService} from "../api/ApiService";
import UserSelect from "../components/UserSelect";
import EditIcon from '@mui/icons-material/Edit';
import {Edit} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const projectsService = new ProjectsService();

export default function ProjectPage(props)
{
    let {projectId} = useParams();
    let [loading, setLoading] = useState(false);
    let [project, setProject] = useState({
        name: '',
        address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postCode: ''
        },
        projectStatus: {
            id: '',
            name: ''
        },
        assignedUser: {
            id: '',
            firstName: '',
            lastName: ''
        },
        details: ''
    });
    useEffect(() => {
        
        const fetchProject = async() => {
            setLoading(true);
            try {
                let response = await projectsService.getProject(projectId);
                if(!response.assignedUser) {
                    response.assignedUser = {
                        id: ''
                    }    
                }
                if(!response.projectStatus) {
                    response.projectStatus = {
                        id: ''
                    }
                }
                setProject(response);
            } catch {
                await snackbarService.showSnackbar(`Whoops, something went wrong.`, 'error');
            } finally {
                setLoading(false);
            }
        }; 

        fetchProject();
    }, [projectId]);

    let changeStatus = (value) => {
        const updateStatus = async() => {
            
            try {
                let response = await projectsService.updateStatus(projectId, { statusId: value });
                setProject(prevState => {
                    return { ...prevState, ...{ projectStatus: { id: value } } };
                });
                await snackbarService.showSnackbar(`Status updated`, 'success');
            } catch {
                await snackbarService.showSnackbar(`There was an error while updating the status`, 'error');
            }
        };

        updateStatus();
    }

    const changeAssignedUser = async (user) => {
        setLoading(true);
        try {
            let response = await projectsService.updateAssignedUser(projectId, user);
            setProject({...project, assignedUser: { id: user, firstName: '', lastName: ''}});
            snackbarService.showSnackbar(`Project assignee changed.`, 'success');
            setLoading(false);
        } catch {
            snackbarService.showSnackbar(`There was an error updating assignee`, 'error');
            setLoading(false);
        }
        
    }

    return (
        <Grid container>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Link component={RouterLink} color="inherit" to={"/projects"}>Projects</Link>
                    <Typography>JB{project.number} - {project.name}</Typography>
                    
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} style={{marginTop: 16}}>
                <div style={{display:'flex', justifyContent: 'space-between'}}>
                    <Typography style={{flexGrow: 1}} variant={"h5"} style={{marginBottom:16}}>JB{project.number} - {project.name}
                    </Typography>
                    <Tooltip title={"Edit"}>
                        <Button component={RouterLink} to={"edit"} style={{flexGrow: 0}} margin={""} variant={"outlined"} startIcon={<EditIcon/>}>
                            Edit
                        </Button>
                    </Tooltip>
                </div>
                <Grid container spacing={2} style={{marginBottom:16}}>
                    <Grid item>
                        <Button variant={"contained"} component={RouterLink} to={"parts"}>Parts</Button>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} component={RouterLink} to={"assemblies"}>Assemblies</Button>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} component={RouterLink} to={""}>Bolts & Items</Button>
                    </Grid>
                    <Grid item>
                        <Button variant={"contained"} component={RouterLink} to={"files"}>Files</Button>
                    </Grid>
                    {/*<Grid item>*/}
                    {/*    <Button variant={"contained"} component={RouterLink} to={"tasks"}>Tasks</Button>*/}
                    {/*</Grid>*/}
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper style={{padding: 16}}>
                            <Grid spacing={2} container>
                                <Grid item sm={6}>
                                    <UserSelect userId={project.assignedUser.id} label={"Assigned User"} onChange={changeAssignedUser} />
                                </Grid>
                                <Grid item sm={6}>
                                    <ProjectStatusSelect value={project.projectStatus.id} onStatusChange={changeStatus}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper style={{padding: 16}}>
                            <Typography variant={"h6"}>Address</Typography>
                            <p>{project.address.line1}</p>
                            <p>{project.address.line2}</p>
                            <p>{project.address.suburb} {project.address.state} {project.address.postCode}</p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper style={{padding: 16}}>
                            <Typography variant={"h6"}>Customer</Typography>
                            <p>{project.customer?.name}</p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{padding: 16}}>
                            <Typography variant={"h6"}>Details</Typography>
                            <p style={{whiteSpace: "pre-wrap"}}>{project.details}</p>
                        </Paper>
                    </Grid>
                </Grid>
                
            </Grid>
            <Box style={{width:'100%'}}>
                <Stack>
                    <Grid container direction={"row-reverse"} style={{paddingTop:'24px', paddingBottom:'12px'}}>
                        <Grid item>
                    <Button variant={"contained"} component={RouterLink} to={"worklog/new-entry"}>New Work Diary Entry</Button>
                        </Grid>
                        <Grid item style={{flexGrow:1}}>
                    <Typography variant={"h5"} >Work Diary</Typography>
                        </Grid>
                    </Grid>
                    <WorkLogList projectId={projectId}/>
                </Stack>
            </Box>
        </Grid>

    )
}