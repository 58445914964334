import React, {SyntheticEvent, useEffect, useState} from 'react';
import {Avatar, ListItem, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {ServiceConfiguration, User, UserService} from "../api/ApiService";
import InputAdornment from "@mui/material/InputAdornment";
type MultiUserSelectProps = {
    selectedUsers: User[],
    label: string,
    onChange: (event: SyntheticEvent, value: User[]) => void,
    helperText: string,
    error: boolean
}

let userService = new UserService(new ServiceConfiguration());

function MultiUserSelect({selectedUsers, label, onChange, helperText, error}: MultiUserSelectProps) {
    let [users, setUsers] = useState<User[]>([]);
    useEffect(() => {
        const getUsers = async () => {
            let usersRequest = await userService.getUsers(true);
            setUsers(usersRequest.users || []);
        }
        getUsers();
    }, []);
    
    const isOptionEqualToValue = (option: User, value: any) => option.id == value.id;

    return (
        <Autocomplete
            isOptionEqualToValue={isOptionEqualToValue}
            multiple={true}
            fullWidth={true}
            sx={{marginTop: '24px'}}
            size={"small"}
            options={users}
            renderInput={(params) => {
                return <TextField {...params} label={label} helperText={helperText} error={error}/>
            }
            }
            onChange={onChange} value={selectedUsers}
            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
            renderOption={(props, option, state) => {
                return <li {...props}>
                    <Avatar src={"https://i.pravatar.cc/72?u=" + option.id}  sx={{width: '30px', height: '30px', marginRight: '12px'}}/>
                    {option.firstName} {option.lastName}
                </li>
            }}
        />
    )
}

export default MultiUserSelect;