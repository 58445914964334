import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
    kg: {
        fontSize: '.8em'
    }
});

export default function WeightMeasurement({ value }) {
    let styles = useStyles();
    return <span>{value} <span className={styles.kg}>kg</span></span>
}