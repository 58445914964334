import React, {useEffect, useState} from 'react';
import {ContactsService} from "../api/ApiService";
import {Autocomplete, Box, TextField, Tooltip} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from "@mui/material/IconButton";
import ContactCreateDialog from "./ClientCreateDialog";

const contactsService = new ContactsService();

export default function ContactSelect({contact, label, onChange}) {
    let [contacts, setContacts] = useState([]);
    let [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);
    useEffect(() => {
        async function loadClients() {
            let results = await contactsService.getContacts();
            setContacts(results.contacts);
        }
        loadClients();    
    }, []);
    
    const onValueChange = (event, value) => {
        onChange(value);
    }
    
    const createClient = () => {
        setCreateClientDialogOpen(true);
    }
    
    const handleDialogClose = (didSave, result) => {
        setCreateClientDialogOpen(false);
        if(!didSave) return;
        console.log(result);
        onChange(result);
        setContacts(prevState => [...prevState, result]);
    }
    
    return (
        <>
            <Box style={{display:'flex', flexDirection:'row-reverse'}}>
            <Tooltip title={"Create new client"}>
                <IconButton color={"primary"} onClick={createClient}>
                    <AddCircleOutlineIcon/>
                </IconButton>
            </Tooltip>
        <Autocomplete style={{flexGrow:1}}
            value={contact}
            label={label}
            fullWidth={true}
            options={contacts}
            renderInput={(params) => {
                return <TextField {...params} label={"Client"} size="small"/> }}
            onChange={onValueChange} 
            renderOption={(props, option) => <li {...props} key={option.id}>{option.name}</li>}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => {
                return option.id === value.id
            }}
            freeSolo
        />
            
        </Box>
            <ContactCreateDialog open={createClientDialogOpen} onClose={handleDialogClose} />
            </>
    )
}