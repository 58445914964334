import React, {useEffect, useState} from 'react';
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import {Input, TableCell, TextField} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import {MaterialProfileService, MaterialService, MaterialTypeService} from "../../api/ApiService";
import TakeOffMaterialCodeInput from "./components/TakeOffMaterialCodeInput";
import TakeOffMaterialTypeInput from "./components/TakeOffMaterialTypeInput";

let data = {
    lines: [
        {
            type: 'group',
            name: 'Columns',
            lines: [
                {
                    length: 3000,
                    material: 'Steel',
                    materialCode: '200UB25',
                    qty: 5
                }
            ]
        }
    ]
}

// type: Group or Line

let materialService = new MaterialService();
let materialTypeService = new MaterialTypeService();

export default function QuotationTakeOff() {

    let [materials, setMaterials] = useState([]);
    let [materialTypes, setMaterialTypes] = useState([]);
    
    let [data, setData] = useState({
        name: 'S1'
    })
    
    useEffect(() => {
        let loadMaterials = async () => {
            let results = await materialService.get();
            setMaterials(results);
            console.log(results);
        }     
        loadMaterials();
    }, []);

    useEffect(() => {
        let loadMaterialTypes = async () => {
            let results = await materialTypeService.get();
            setMaterialTypes(results);
            console.log(results);
        }
        loadMaterialTypes();
    }, []);
    
    let setMaterialCode = (value) => {
        setData({ ...data, materialCode: value});
    }

    let setMaterialType = (value) => {
        setData({ ...data, materialType: value});
    }
    
    let getWeight = (data) => {
        let material = materials.filter(i => i.code === data.materialCode);
        if(material.length === 1) return materials[0].weight;
        return "";
    }

    let getTotalWeight = (data) => {
        console.log(data);
        let material = materials.filter(i => i.code === data.material);
        console.log(material);
        if(material.length === 1) return materials[0].weight;
        return "";
    }
    
    return (
        <div>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Material Type</TableCell>
                    <TableCell>Material Code</TableCell>
                    <TableCell>Surface Finish</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Length</TableCell>
                    <TableCell>Width</TableCell>
                    <TableCell>Weight</TableCell>
                    <TableCell>Total Weight</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <TextField value={data.name} size={"small"}/>
                    </TableCell>
                    <TableCell>
                        <TakeOffMaterialTypeInput materialTypes={materialTypes} value={data.materialType} onValueChange={(v) => setMaterialType(v)}/>
                    </TableCell>
                    <TableCell>
                        <TakeOffMaterialCodeInput materials={materials} value={data.materialCode} onValueChange={(e, v) => setMaterialCode(v)}/>
                    </TableCell>
                    <TableCell>
                        <TakeOffMaterialCodeInput materials={materials} value={data.materialCode} onValueChange={(e, v) => setMaterialCode(v)}/>
                    </TableCell>
                    <TableCell>
                        <TextField value={data.qty} size={"small"} sx={{width: '4em'}}/>
                    </TableCell>
                    <TableCell>
                        <TextField value={data.length} size={"small"} sx={{width: '8em'}}/>
                    </TableCell>
                    <TableCell>
                        <TextField value={data.width} size={"small"} sx={{width: '8em'}}/>
                    </TableCell>
                    <TableCell>
                        {getWeight(data)}
                    </TableCell>
                    <TableCell>
                        <TextField value={getTotalWeight(data)} size={"small"} sx={{width: '8em'}}/>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        </div>
    );
}

function TakeOffGroup({group}) {
    return (
        <>
            {group.lines.map(l => {
                if(l.type === 'group') return <TakeOffGroup group={l}/>
                else return <TakeOffLine line={l}/> 
            })}
        </>
    )
}

function TakeOffLine({line}) {
    return (
        <TableRow>
            <TableCell>{line.name}</TableCell>
            <TableCell>{line.materialType}</TableCell>
            <TableCell>{line.materialCode}</TableCell>
            <TableCell>{line.surfaceFinish}</TableCell>
            <TableCell>{line.qty}</TableCell>
            <TableCell>{line.length}</TableCell>
            <TableCell>{line.width}</TableCell>
        </TableRow>
    )
}