import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GoogleMaps({onChangeAddress}) {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyAGnX_GJEzGKxrdVfKk_ik_pd6TNS39yQ0&libraries=places',
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions({...request, componentRestrictions: { country: 'au'}}, callback);
            }, 500),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    function fillInAddress(value) {
        if(value === null) return;
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId: value.place_id }, (results, status) => {
            if (status !== "OK") {
                window.alert("Geocoder failed due to: " + status);
                return;
            }
            let fields = [];
            if(results[0]) {
                results[0].address_components.forEach(component => {
                    fields[component.types[0]] = component.long_name;
                });
                if(fields['street_number'] === undefined) fields['street_number'] = '';
                if(fields['route'] === undefined) fields['route'] = '';
                if(fields['locality'] === undefined) fields['locality'] = '';
                if(fields['postal_code'] === undefined) fields['postal_code'] = '';
                if(fields['administrative_area_level_1'] === undefined) fields['administrative_area_level_1'] = '';
                if(fields['country'] === undefined) fields['country'] = '';
                let addressLine1 = `${fields['street_number']} ${fields['route']}`.trim();
                let addressLine2 = ``;
                let city = `${fields['locality']}`.trim();
                let postCode = `${fields['postal_code']}`.trim();
                let state = `${fields['administrative_area_level_1']}`.trim();
                let country = `${fields['country']}`.trim();
                onChangeAddress({
                    addressLine1,
                    addressLine2,
                    city,
                    postCode,
                    state,
                    country
                });
            }
        });
        
        /*for (const component in componentForm) {
            document.getElementById(component).value = "";
            document.getElementById(component).disabled = false;
        }

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        for (const component of place.address_components) {
            const addressType = component.types[0];

            if (componentForm[addressType]) {
                const val = component[componentForm[addressType]];
                document.getElementById(addressType).value = val;
            }
        }*/
    }

    return (
        <Autocomplete
            id="google-map-demo"
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                console.log("onchange:", event, newValue);
                setOptions(newValue ? [newValue, ...options] : options);
                fillInAddress(newValue);
                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} size={"small"} label="Address Search" fullWidth />
            )}
            renderOption={(props, option) => {
                console.log(option);
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match) => [match.offset, match.offset + match.length]),
                );

                return (
                    <li {...props}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <LocationOnIcon className={classes.icon} />
                            </Grid>
                            <Grid item xs>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                                ))}
    
                                <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
