import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router";
import {WorkLogService} from "../../api/ApiService";
import PageLoadingIndicator from "../../components/ui/PageLoadingIndicator";
import Container from "@mui/material/Container";
import {Stack, TextField, Typography} from "@mui/material";
import ProjectSelect from "../../components/ProjectSelect";
import MultiUserSelect from "../../components/MultiUserSelect";
import {DatePicker, TimePicker} from "@mui/lab";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { LocalDate, LocalTime, nativeJs} from "@js-joda/core";
import {snackbarService} from "../../components/ui/snackbar/SnackbarService";
import {styled} from "@mui/styles";

const workDiaryService = new WorkLogService();

const Input = styled('input')({
    display: 'none',
});

export default function EditWorkDiaryEntry()  {
    let { id } = useParams();
    let [loading, setLoading] = useState(true);
    let [entry, setEntry] = useState({ users: []});
    let [errors, setErrors] = useState([]);
    let [files, setFiles] = useState([]);
    let navigate = useNavigate();
    let uploadInput = useRef(null);

    let handleUserChange = (event, users) => {
        setEntry(prevState => ({ ...prevState, users: users}));
    }

    let handleDateChange = (value) => {
        setEntry(prevState => ({ ...prevState, date: value}));
    }

    let handleFileInput = (val) => {
        if(val.target.files && val.target.files.length > 0) {
            setFiles([...files, ...val.target.files]);
        }
    }

    let handleStartTimeChange = (value) => setEntry(prevState => ({ ...prevState, startTime: value}));
    let handleEndTimeChange = (value) => setEntry(prevState => ({ ...prevState, endTime: value}));
    let handleDetailsChange = (event) => setEntry(prevState => ({ ...prevState, details: event.target.value}));
    let handleProjectChange = (value) => setEntry(prevState => ({ ...prevState, projectId: value}));

    const hasError = (field) => {
        return field && field.length > 0;
    }

    const errorString = (fieldName) => {
        return errors[fieldName]?.join(",");
    }
    
    useEffect(() => {
        const loadWorkDiaryEntry = async () => {
            setLoading(true);
            let response = await workDiaryService.getWorkDiaryEntry(id);
            let hours = LocalTime.parse(response.startTime).hour();
            let minute = LocalTime.parse(response.startTime).minute();
            response.startTime = new Date();
            response.startTime.setHours(hours);
            response.startTime.setMinutes(minute);

            let hours2 = LocalTime.parse(response.endTime).hour();
            let minute2 = LocalTime.parse(response.endTime).minute();
            response.endTime = new Date();
            response.endTime.setHours(hours2);
            response.endTime.setMinutes(minute2);

            setEntry(response);
            setLoading(false);
        }

        loadWorkDiaryEntry();
    }, [id]);

    let handleSaveWorkLog = async () => {
        let formData = new FormData();
        formData.append('projectId', entry.projectId);
        entry.users.forEach(u => formData.append('userIds[]', u.id));
        formData.append('date', entry.date ? LocalDate.from(nativeJs(entry.date)).toString() : null);
        formData.append('startTime', entry.startTime != null ? LocalTime.from(nativeJs(entry.startTime)).toString() : null);
        formData.append('endTime',  entry.endTime != null ? LocalTime.from(nativeJs(entry.endTime)).toString() : null);
        formData.append('details', entry.details);
        files.forEach(f => formData.append('files', f, f.name));

        setLoading(true);
        fetch(`/api/worklog/${id}`, {
            method: 'POST',
            body: formData
        })
            .then(res => {
                if(res.status === 400) {
                    snackbarService.showSnackbar(`There was an error updating the work diary entry`, 'error');
                    res.json().then((response) => {
                        setErrors(response.errors);
                        setLoading(false);
                    });
                } else {
                    snackbarService.showSnackbar(`Work diary updated!`, 'success');
                    navigate('../../../');
                }})
    }
    
    return (
        <>
            <PageLoadingIndicator loading={loading}/>
            <Container maxWidth={"md"}>
                <Stack spacing={3} >
                    <Typography variant={"h5"}>Work Diary Entry</Typography>

                    <ProjectSelect project={entry.projectId} onChange={handleProjectChange} disabled={true} error={hasError(errors["ProjectId"])} helperText={errorString("ProjectId")}/>
                    <MultiUserSelect selectedUsers={entry.users} label={"Users"} onChange={handleUserChange} error={hasError(errors["UserIds"])} helperText={errorString("UserIds")}/>
                    <DatePicker
                        label="Date"
                        value={entry.date}
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} fullWidth size={"small"} error={hasError(errors["Date"])}
                                                            helperText={errors["Date"]?.join(',')} />}

                    />
                    <TimePicker
                        label="Start Time"
                        value={entry.startTime}
                        onChange={handleStartTimeChange}
                        renderInput={(params) => <TextField  {...params} fullWidth size={"small"} error={hasError(errors["StartTime"])} helperText={errorString("StartTime")}/>}
                    />
                    <TimePicker
                        label="End Time"
                        value={entry.endTime}
                        onChange={handleEndTimeChange}
                        renderInput={(params) => <TextField {...params} fullWidth size={"small"} error={hasError(errors["EndTime"])} helperText={errorString("EndTime")}/>}
                        margin="dense"
                    />
                    <TextField InputLabelProps={{ shrink: true }} label="Details" rows={10} multiline={true} defaultValue="" value={entry.details} onChange={handleDetailsChange} size={"small"} />
                    <label htmlFor="contained-button-file">
                        <Input accept="image/*" id="contained-button-file" multiple type="file" ref={uploadInput} onChange={handleFileInput}/>
                        <Button variant="outlined" component="span">
                            Attach Photos & Files
                        </Button>
                    </label>
                    <Stack spacing={2}>
                        {files.map(f => <FileUploadPreview key={f.name} file={f}/>) }
                    </Stack>
                    <Grid container direction={"row-reverse"}>
                        <Grid item><Button variant={"contained"} color="primary" onClick={handleSaveWorkLog}>Save</Button></Grid>
                        <Grid item><Button variant={"text"}>Cancel</Button></Grid>
                    </Grid>
                </Stack>
            </Container>
        </>
    )
}

function FileUploadPreview({file}) {
    let imageData = null;
    if(file.type.startsWith('image/')) {
        imageData = URL.createObjectURL(file);
    }
    useEffect(() => {

    }, [file]);

    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {imageData && <img style={{maxWidth: 36, maxHeight: 36}} alt={file.name} src={imageData}/>}
            {!imageData && <InsertDriveFileIcon fontSize={"large"}/>}
            <span style={{marginLeft: '8px'}}>{file.name}</span>
        </div>
    )
}