import React, { useState, useEffect, Fragment } from 'react';
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import PageLoadingIndicator from "../components/ui/PageLoadingIndicator";
import MaterialProfileList from "../components/materials/MaterialProfileList";
import MaterialList from "../components/materials/MaterialList";
import StockLocationList from "../components/inventory/locations/StockLocationList";

export default function AddStock() {
    let initialState = {
        error: null,
        stockLocationLoaded: false,
        materialLoaded: false,
        stockLocationList: [],
        materialProfilesLoaded: false,
        materialProfiles: [],
        materialList: [],
        materialId: '',
        stockLocationId: '',
        materialProfileId: '',
        length: '',
        qty: 1,
        isLoading: false
    }

    let [state, setState] = useState(initialState);
    let navigate = useNavigate();

    const getCodesFilteredByProfile = () => {
        if(!state.materialProfileId) return state.materialList;
        return state.materialList.filter(item => item.materialProfileId === state.materialProfileId);
    };

    const onMaterialChanged = (value) => {
        setState(s => {
            return {
            ...s,
            materialId: value
        }})
    };

    const onLengthChanged = (event) => {
        let value = event.target.value;
        setState(s => {
            return {
            ...s,
            length: value
        }});
    };

    const onQtyChanged = (event) => {
        let value = event.target.value;
        setState(s => {
            return {
            ...s,
            qty: value
        }});
    };

    const onStockLocationChanged = (value) => {
        setState(s => {
            return {
            ...s,
            stockLocationId: value
        }});
    };
    
    const onMaterialProfileChanged = (value) => {
        setState(s => {
            return {
                ...s,
                materialProfileId: value
        }});
    };

    const addStockItem = () => {
        setState(s => {
            return {
                ...s,
                isLoading: true
        }});
        fetch(`/api/stock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    materialId: state.materialId,
                    stockLocationId: state.stockLocationId,
                    qty: state.qty,
                    length: parseInt(state.length, 10)
                }
            )
        }).then( (result) => navigate(`/stock`));
    };

    useEffect(() => {
        fetch(`/api/stock-locations`)
        .then(res => res.json())
        .then(
            (result) => {
                setState(s => { 
                    return {
                    ...s,
                    stockLocationLoaded: true,
                    stockLocationList: result
                }});
                
            }
        );

    fetch(`/api/material`)
    .then(res => res.json())
    .then(
        (result) => {
            setState(s => { 
                return {
                ...s,
                materialLoaded: true,
                materialList: result
            }});
        }
    );

    fetch(`/api/material-profiles`)
        .then(res => res.json())
        .then(
            (result) => {
                setState(s => { 
                    return {
                    ...s,
                    materialProfilesLoaded: true,
                    materialProfiles: result
                }});
            }
        )
    }, []);

    const { error } = state;
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!state.stockLocationLoaded || !state.materialLoaded || !state.materialProfilesLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <Fragment>
                <PageLoadingIndicator loading={state.isLoading}/>
            <Grid container>
                
                <Grid item xs={12} style={{'paddingTop': 16}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} to="/">
                            Home
                        </Link>
                        <Link component={RouterLink} to={"/stock"}>
                            Stock
                        </Link>
                        <Typography color="textPrimary">Add Stock</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} style={{'paddingTop': 16}}>
                    <form>
                        <MaterialProfileList materialProfileIdSelected={state.materialProfileId} materialProfileList={state.materialProfiles} onMaterialProfileChange={onMaterialProfileChanged}/>
                        <MaterialList materialIdSelected={state.materialId} materialList={getCodesFilteredByProfile()} onMaterialChange={onMaterialChanged}/>
                        <StockLocationList stockLocationIdSelected={state.stockLocationId} stockLocationList={state.stockLocationList} onStockLocationChange={onStockLocationChanged}/>
                        <TextField
                            autoFocus
                            id="length"
                            label="Length"
                            type="number"
                            fullWidth
                            InputProps={{
                                endAdornment: <InputAdornment position="end">mm</InputAdornment>,
                            }}
                            onChange={onLengthChanged}
                            value={state.length}
                        />
                        <TextField
                            autoFocus
                            id="qty"
                            label="Quantity"
                            type="number"
                            fullWidth
                            onChange={onQtyChanged}
                            value={state.qty}
                        />
                    </form>
                    
                </Grid>
                <Grid xs={12} style={{'paddingTop': 16}} item>
                    <Button style={{float: 'right'}}  variant={"contained"} color="primary" className="float-right" onClick={addStockItem}>Add Stock Item</Button>
                    <Button style={{float: 'right'}}  variant={"text"} component={RouterLink} to={"/stock"}>Cancel</Button>
                </Grid>
            </Grid>
            </Fragment>
        )
    } 
}