import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link as RouterLink } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';

export default function StockItem() {
    let navigate = useNavigate();
    let initialState = {
            error: null,
            loaded: false,
            qty: 1,
            item: {},
            stockLocationLoaded: false,
            stockLocations: []
    };
 
    let [state, setState] = useState(initialState);
    let params = useParams();

    const onQtyChanged = (event) => {
        let value = event.target.value;
        setState(s => ({
            ...s,
            qty: value
        }))
    };

    const pullStockItem = () => {
        fetch(`/api/stock/pull?stockId=${params.id}&qty=${state.qty}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
              }
        }).then( (result) => {
            navigate(`/stock`);
        })
    }

    useEffect(() => {
        fetch(`/api/stock/${params.id}`)
        .then(res => res.json())
        .then(
            (result) => {
                setState(s => ({
                    ...s,
                    loaded: true,
                    item: result
                }));
            }
        )

    fetch(`/api/stock-locations`)
        .then(res => res.json())
        .then(
            (result) => {
                setState(s => ({
                    ...s,
                    stockLocationLoaded: true,
                    stockLocations: result
                }));
            }
        );
    }, [params.id]);

    const getStockLocationCode = (id) => {
        let code = '';
        let stockLocation = state.stockLocations.filter((i) => i.id === id);
        if(stockLocation.length === 1) {
            code = stockLocation[0].code;
            if(stockLocation[0].parentId !== null) {
                return getStockLocationCode(stockLocation[0].parentId) + '-' + code;
            }
        }
        return code;
    };

    const { error } = state;
    if (error) {
    return <div>Error: {error.message}</div>;
    } else if (!state.loaded || !state.stockLocationLoaded) {
    return <div>Loading...</div>;
    } else {
        return (
            <Grid container>
                <Grid item xs={12} style={{'paddingTop': 16}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/">
                            Home
                        </Link>
                        <Link  component={RouterLink} color="inherit" to={"/stock"}>
                            Stock
                        </Link>
                        <Typography color="textPrimary">Stock Item</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item>
                    <p><strong>Product Code: </strong>{state.item.code}</p>
                    <p><strong>Length: </strong>{state.item.length}mm</p>
                    <p><strong>Quantity Available: </strong>{state.item.qty}</p>
                    <p><strong>Location: </strong>{getStockLocationCode(state.item.stockLocationId)}</p>
                </Grid>
                <Grid item xs={12} style={{'paddingTop': 16}}>
                    <form>
                        <TextField
                            autoFocus
                            id="qty"
                            label="Quantity"
                            type="number"
                            fullWidth
                            onChange={onQtyChanged}
                            value={state.qty}
                        />
                    </form>

                </Grid>
                <Grid xs={12} style={{'paddingTop': 16}} item>
                    <Button style={{float: 'right'}}  variant={"contained"} color="primary" className="float-right" onClick={pullStockItem}>Pull Stock Item</Button>
                    <Button style={{float: 'right'}}  component={RouterLink} variant="text" to={"/stock"}>Cancel</Button>
                </Grid>
            </Grid>
            
        );
    }
}