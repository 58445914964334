import React from "react";
import PartsListItemStatusLine from "./PartsListItemStatusLine";
import styles from './PartsList.module.css'
export default function PartsListItemStatus({partStatus}) {
    let statusLines = partStatus.map(s => <PartsListItemStatusLine partStatusLine={s} key={s.statusCode}/>);
    return (
        <div className={styles.rowContainer}>
            {statusLines}
        </div>
    )
}