import React, {useEffect, useRef, useState} from 'react';
import {DatePicker, TimePicker} from "@mui/lab";
import { Stack, TextField, Typography} from "@mui/material";
import MultiUserSelect from "../../components/MultiUserSelect";
import Button from "@mui/material/Button";
import {styled} from "@mui/styles";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ProjectSelect from "../../components/ProjectSelect";
import {useParams, } from "react-router";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { WorkLogService} from "../../api/ApiService";
import {useNavigate} from "react-router-dom";
import {LocalDate, LocalTime, nativeJs} from "@js-joda/core";
import PageLoadingIndicator from "../../components/ui/PageLoadingIndicator";
import {snackbarService} from "../../components/ui/snackbar/SnackbarService";
let workLogService = new WorkLogService();

const Input = styled('input')({
    display: 'none',
});

export default function EnterProjectWorkLog() {
    let [errors, setErrors] = useState([]);
    let [users, setUsers] = useState([]);
    let [date, setDate] = useState(new Date());
    let [startTime, setStartTime] = useState(null);
    let [endTime, setEndTime] = useState(new Date());
    let [details, setDetails] = useState('');
    let [files, setFiles] = useState([]);
    let [project, setProject] = useState(null);
    let [loading, setLoading] = useState(false);
    let {projectId} = useParams();
    let navigate = useNavigate();
    useEffect(() => {
        setProject(projectId);
    }, [projectId]);
    
    
    let uploadInput = useRef(null);
    
    let handleUserChange = (event, users) => {
        console.log(users);
        setUsers(users);
    }
    
    let handleDateChange = (value) => {
        setDate(value);
    }
    
    let handleFileInput = (val) => {
        if(val.target.files && val.target.files.length > 0) {
                setFiles([...files, ...val.target.files]);
        }
        console.log(...files, ...val.target.files);
    }
    
    let handleStartTimeChange = (value) => setStartTime(value);
    let handleEndTimeChange = (value) => setEndTime(value);
    let handleDetailsChange = (event) => setDetails(event.target.value);
    let handleProjectChange = (value) => { setProject(value)}
    
    let handleSaveWorkLog = async () => {
        let formData = new FormData();
        formData.append('projectId', project ? (typeof project === 'string') ? project : project.id : '');
        users.forEach(u => formData.append('userIds[]', u.id));
        formData.append('date', date ? LocalDate.from(nativeJs(date)).toString() : null);
        formData.append('startTime', startTime != null ? LocalTime.from(nativeJs(startTime)).toString() : null);
        formData.append('endTime',  LocalTime.from(nativeJs(endTime)).toString());
        formData.append('details', details);
        files.forEach(f => formData.append('files', f, f.name));
        
        setLoading(true);
        fetch(`/api/worklog`, {
            method: 'POST',
            body: formData
        })
            .then(res => {
                if(res.status === 400) {
                    snackbarService.showSnackbar(`There was an error creating the work diary entry`, 'error');
                    res.json().then((response) => {
                        console.log(response.errors);
                        setErrors(response.errors);
                        setLoading(false);
                    });
                } else {
                            snackbarService.showSnackbar(`Work diary created!`, 'success');
                            navigate('../../');
                }})
    }
    
    const hasError = (field) => {
        console.log(field);
        return field && field.length > 0;
    }
    
    const errorString = (fieldName) => {
        return errors[fieldName]?.join(",");
    }
    
    return (
        <>
            <PageLoadingIndicator loading={loading}/>
        <Container maxWidth={"md"}>
            <Stack spacing={3} >
                <Typography variant={"h5"}>Work Diary Entry</Typography>

                <ProjectSelect project={project} onChange={handleProjectChange} disabled={true} error={hasError(errors["ProjectId"])} helperText={errorString("ProjectId")}/>
                <MultiUserSelect selectedUsers={users} label={"Users"} onChange={handleUserChange} error={hasError(errors["UserIds"])} helperText={errorString("UserIds")}/>
                <DatePicker
                    label="Date"
                    value={date}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} fullWidth size={"small"} error={hasError(errors["Date"])}
                                                        helperText={errors["Date"]?.join(',')} />}
                    
                />
                    <TimePicker
                        label="Start Time"
                        value={startTime}
                        onChange={handleStartTimeChange}
                        renderInput={(params) => <TextField  {...params} fullWidth size={"small"} error={hasError(errors["StartTime"])} helperText={errorString("StartTime")}/>}
                    />
                    <TimePicker
                        label="End Time"
                        value={endTime}
                        onChange={handleEndTimeChange}
                        renderInput={(params) => <TextField {...params} fullWidth size={"small"} error={hasError(errors["EndTime"])} helperText={errorString("EndTime")}/>}
                        margin="dense"
                    />
                    <TextField label="Details" rows={10} multiline={true} value={details} onChange={handleDetailsChange} size={"small"} />
                            <label htmlFor="contained-button-file">
                                <Input accept="image/*" id="contained-button-file" multiple type="file" ref={uploadInput} onChange={handleFileInput}/>
                                <Button variant="outlined" component="span">
                                    Attach Photos & Files
                                </Button>
                            </label>
                        <Stack spacing={2}>
                            {files.map(f => <FileUploadPreview key={f.name} file={f}/>) }
                        </Stack>
                <Grid container direction={"row-reverse"}>
                    <Grid item><Button variant={"contained"} color="primary" onClick={handleSaveWorkLog}>Save</Button></Grid>
                    <Grid item><Button variant={"text"}>Cancel</Button></Grid>
                </Grid>
            </Stack>
        </Container>
        </>
    );
}

function FileUploadPreview({file}) {
    let imageData = null;
    if(file.type.startsWith('image/')) {
        imageData = URL.createObjectURL(file);
    }
    useEffect(() => {
        
    }, [file]);
    
    return (
        <div style={{display: 'flex', alignItems: 'center'}}>
            {imageData && <img style={{maxWidth: 36, maxHeight: 36}} alt={file.name} src={imageData}/>}
            {!imageData && <InsertDriveFileIcon fontSize={"large"}/>}
            <span style={{marginLeft: '8px'}}>{file.name}</span>
        </div>
    )
}

/*
public IList<WorkLogEntryUser> Users { get; set; }
        public DateTime Date { get; set; }
        public DateTime StartTime { get; set; }
        public DateTime EndTime { get; set; }
        public String Details { get; set; }
        public IList<WorkLogEntryFile> Attachments { get; set; }
 */