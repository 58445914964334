import React, {useEffect, useState} from 'react';
import {TasksService} from "../api/ApiService";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import FormControl from "@mui/material/FormControl";

const tasksService = new TasksService();

function TaskStatusSelect({taskStatusId, onStatusChange}) {
    let [statuses, setStatuses] = useState([]);
    
    useEffect(() => {
        const loadStatuses = async () => {
            let response = await tasksService.getTaskStatuses();
            setStatuses(response.statuses);
        }
        
        loadStatuses();
    }, []);

    const handleStatusChange = (e) => {
        onStatusChange(e.target.value);
    }
    
    return (
        <FormControl sx={{width: '100%'}}>
            <InputLabel>Status</InputLabel>
            <Select size={"small"}
                label="Status"
                onChange={handleStatusChange} value={taskStatusId}
            >
                {statuses.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default TaskStatusSelect;