import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {ContactsService} from "../api/ApiService";
import {snackbarService} from "./ui/snackbar/SnackbarService";
import PageLoadingIndicator from "./ui/PageLoadingIndicator";

let contactsService = new ContactsService();

export default function ContactCreateDialog({open, onClose, isCustomer = false, isSupplier = false}) {
    let title = 'Contact';
    if(!isCustomer) title = 'Supplier';
    if(!isSupplier) title = 'Customer';
    
    let [error, setError] = useState(false);
    let [errors, setErrors] = useState([]);
    
    let [loading, setLoading] = useState(false);
    let [contact, setContact] = useState({
        name: '',
        businessNumber: '',
        taxNumber: '',
        emailAddress: ''
    })
    
    const handleClose = () => {
        onClose(false);
    }
    
    const handleSave = async () => {
        setError(false);
        setErrors([]);
        setLoading(true);
        try {
            let result = await contactsService.createContact({
                name: contact.name,
                businessNumber: contact.businessNumber,
                emailAddress: contact.emailAddress,
                taxNumber: contact.taxNumber,
                isSupplier: isSupplier,
                isCustomer: isCustomer
            });
            snackbarService.showSnackbar(`Contact created`, 'success');
            console.log(result);
            onClose(true, result.contact);
        } catch(ex) {
            console.log(ex);
            if(ex.status === 400) {
                setError(true);
                setErrors(ex.response.errors);
            }
            snackbarService.showSnackbar(`There was an error creating the contact`, 'error');
        } finally {
            setLoading(false);
        }
    }

    const hasError = (field) => {
        return field && field.length > 0;
    }


    function handleNameChange(event) {
        setContact(prevState => ({...prevState, name: event.target.value}) )
    }

    function handleBusinessNumberChange(event) {
        setContact(prevState => ({...prevState, businessNumber: event.target.value}) )
    }

    function handleTaxNumberChange(event) {
        setContact(prevState => ({...prevState, taxNumber: event.target.value}) )
    }

    function handleEmailAddressChange(event) {
        setContact(prevState => ({...prevState, emailAddress: event.target.value}) )
    }

    return (
        <>
            <PageLoadingIndicator loading={loading}/>
            <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>New {title}</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            error={hasError(errors["Name"])}
                            helperText={hasError(errors["Name"]) ? errors["Name"]?.join(', ') : null}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="string"
                            fullWidth
                            size={"small"}
                            value={contact.name}
                            onChange={handleNameChange}
                        />
                        <TextField
                            id="businessNumber"
                            label="Business Number ( ABN / CBN )"
                            type="string"
                            fullWidth
                            size={"small"}
                            value={contact.businessNumber}
                            onChange={handleBusinessNumberChange}
                        />
                        <TextField
                            id="taxNumber"
                            label="Tax Number"
                            type="string"
                            fullWidth
                            size={"small"}
                            value={contact.taxNumber}
                            onChange={handleTaxNumberChange}
                        />
                        <TextField
                            error={hasError(errors["EmailAddress"])}
                            helperText={hasError(errors["EmailAddress"]) ? errors.EmailAddress?.join(', ') : null}
                            id="emailAddress"
                            label="Email Address"
                            type="email"
                            fullWidth
                            size={"small"}
                            value={contact.emailAddress}
                            onChange={handleEmailAddressChange}
                        />
                    </Stack>

                    {/*<Typography variant={"h6"} sx={{marginTop: '16px'}}>Contacts</Typography>
                    <Grid container spacing={1}>
                        <Grid item lg={3}>
                            <TextField
                                margin="dense"
                                id="contactFirstName"
                                label="First Name"
                                type="string"
                                size={"small"}
                            />
                        </Grid>
                        <Grid item lg={3}>
                            <TextField
                                margin="dense"
                                id="contactLastName"
                                label="Last Name"
                                type="string"
                                size={"small"}
                            />
                        </Grid>
                        <Grid item lg={3}>
                            <TextField
                                margin="dense"
                                id="contactPhoneNumber"
                                label="Phone Number"
                                type="phone"
                                size={"small"}
                            />
                        </Grid>
                        <Grid item lg={3}>
                            <TextField
                                margin="dense"
                                id="contactEmail"
                                label="Email"
                                type="email"
                                size={"small"}
                            />
                        </Grid>
                    </Grid>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button onClick={() => handleSave()}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}