import React, {SyntheticEvent, useEffect, useState} from 'react';
import {Avatar, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {ServiceConfiguration, User, UserService} from "../api/ApiService";
import InputAdornment from "@mui/material/InputAdornment";
type UserSelectProps = {
    userId: string,
    label: string,
    onChange: (value: string | undefined) => void
}

let userService = new UserService(new ServiceConfiguration());

function UserSelect({userId, label, onChange}: UserSelectProps) {
    console.log(userId);
    let [users, setUsers] = useState<User[]>([]);
    let [selectedUser, setSelectedUser] = useState<User | null>(null);
    useEffect(() => {
        const getUsers = async () => {
            let usersRequest = await userService.getUsers(true);
            setUsers(usersRequest.users || []);
        }
        getUsers();
    }, []);
    
    useEffect(() => {
        let selectedUser = users.filter(i => i.id === userId);
        if(selectedUser.length === 1) {
            setSelectedUser(selectedUser[0]);
        } else {
            setSelectedUser(null);
        }
    }, [users, userId]);
    
    let handleChange = (event: SyntheticEvent, user: User | null | string) => {
        if(typeof user === "string") {
            onChange(undefined);
        }
        else { onChange(user?.id) }
    }
    
    const isOptionEqualToValue = (option: User, value: User) => {
        return option.id === value.id;
    }
    
    return (
        <Autocomplete
            fullWidth={true}
            options={users}
            renderInput={(params) => {
                
                if(selectedUser) {
                    params.InputProps.startAdornment = (
                        <InputAdornment position="start">
                            <Avatar src={"https://i.pravatar.cc/72?u=" + selectedUser.id}  sx={{width: '30px', height: '30px'}}/>
                        </InputAdornment>);
                }
                return <TextField {...params} label={label} size={"small"}/>
                }
            }
            onChange={handleChange} value={selectedUser}
            isOptionEqualToValue={isOptionEqualToValue}
            getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
            renderOption={(props, option, state) => {
                return <li {...props}>
                    <Avatar src={"https://i.pravatar.cc/72?u=" + option.id}  sx={{width: '30px', height: '30px', marginRight: '12px'}}/>
                    {option.firstName} {option.lastName}
                </li>
                }}
        />
    )
}

export default UserSelect;