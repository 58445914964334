import React from 'react';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {MenuItem} from "@mui/material";

function getStockLocationCode (locationList, id) {
    let code = '';
    let stockLocation = locationList.filter((i) => i.id === id);
    if(stockLocation.length === 1) {
        code = stockLocation[0].code;
        if(stockLocation[0].parentId !== null) {
            return getStockLocationCode(locationList, stockLocation[0].parentId) + '-' + code;
        }
    }
    return code;
}

function StockLocationList({onStockLocationChange, stockLocationList, stockLocationIdSelected}) {
    stockLocationList = stockLocationList.map((location) => {
        let loc = location;
        location.fullCode = getStockLocationCode(stockLocationList, location.id);
        return loc;
    }).sort((a,b) => a.fullCode.localeCompare(b.fullCode, 'en', { numeric: true}));

    return (
        <FormControl sx={{width: '100%', marginTop: '24px'}}>
            <InputLabel>Stock Location</InputLabel>
            <Select
                    label="Stock Location"
                    onChange={e => onStockLocationChange(e.target.value)} value={stockLocationIdSelected}
            >
                <MenuItem value={""}>Any</MenuItem>
                {stockLocationList.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.fullCode}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default StockLocationList;