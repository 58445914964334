import React, {useState} from 'react';
import authService from "../auth/AuthorizeService";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Breadcrumbs } from "@mui/material";
import Button from "@mui/material/Button";
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import {snackbarService} from "../components/ui/snackbar/SnackbarService";
import PageLoadingIndicator from "../components/ui/PageLoadingIndicator";

export default function InviteUserPage(props) 
{
    let [user, setUser] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: ''
    });
    let [loading, setLoading] = useState(false);
    let navigate = useNavigate();
    
    let navigateToUsersPage = () => {
        navigate('/users');
    };

    let  inviteUser = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        let headers = { 'Content-Type': 'application/json'};
        if(token) headers['Authorization'] =  `Bearer ${token}`;
        const request = await fetch(`/api/users/invite`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(user)
        });
        try{
            let response = await request.json();
            if(response.error) {
                snackbarService.showSnackbar(`There was an error while attempting to send an invitation`, 'error');
            } else {
                snackbarService.showSnackbar(`An invitation has been sent to ${user.emailAddress}`, 'success');
                navigateToUsersPage();
            }
        } catch {
            setLoading(false);
        }
    }
    
    return (
        <Grid container>
            <PageLoadingIndicator loading={loading}/>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} color="inherit" to="/">
                        Home
                    </Link>
                    <Link  component={RouterLink} color="inherit"  to={"/users"}>Users</Link>
                    <Typography >Invite User</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}  md={7} style={{'paddingTop': 16}}>
                
                <Typography variant={"h5"}>Invite User</Typography>
                <Typography>
                    The user will be sent an email invitation to join.
                    They will be able to set a password once they have confirmed their email address.
                </Typography>
            </Grid>
            <Grid item xs={12} md={7} style={{'paddingTop': 16}}>
                <form>
                   <TextField
                        autoFocus
                        id="firstName"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={user.firstName}
                        onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                    />
                    <TextField
                        id="lastName"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={user.lastName}
                        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                    />
                    <TextField
                        id="emailAddress"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={user.emailAddress}
                        onChange={(e) => setUser({ ...user, emailAddress: e.target.value })}
                    />
                    <TextField
                        id="phoneNumber"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        value={user.phoneNumber}
                        onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
                    />
                    <Button style={{float: 'right', marginTop: '16px'}}  variant="contained" color="primary" onClick={() => inviteUser()}>Invite User</Button>
                </form>
            </Grid>
        </Grid>
        
    )
}