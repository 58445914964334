import React, {Fragment} from 'react';

export default function ProjectDirectorySelectorOption({directories, directory, depth}) {
    const children = directories.filter(i => i.parentId === directory.id);
    return (
        <Fragment>
            <option value={directory.id}>{'\xa0'.repeat(depth*3)}{directory.name}</option>
            {children.map(i => (
                <ProjectDirectorySelectorOption key={i.id} directories={directories} directory={i} depth={depth+1}/>
            ))}
        </Fragment>
    )
}