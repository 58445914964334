import React, {useEffect, useState} from 'react';
import styles from './TaskScheduler.module.css';
import {UserService} from "../api/ApiService";

const userService = new UserService();

export default function TaskScheduler() {
    let [users, setUsers] = useState([]);
    let [days, setDays] = useState([
        { day: 'MON', date: 7},    
        { day: 'TUE', date: 8},    
        { day: 'WED', date: 9},    
        { day: 'THU', date: 10},    
        { day: 'FRI', date: 11},    
        { day: 'SAT', date: 12},    
        { day: 'SUN', date: 13}    
    ]);
    useEffect(() => {
        const loadUsers = async  () => {
            let response = await userService.getUsers(true);
            setUsers(response.users);
        }   
        
        loadUsers();
    });
    
    return (
        <div className={styles.scheduleGrid}>
            <div>&nbsp;</div>
            {days.map(d => <DayHeader date={d.date} day={d.day}/>)}
            {users.map(u => <TaskSchedulerRow user={u} />)}
        </div>
    );
}

function DayHeader({date, day}) {
    return (
        <div className={styles.dayHeader}>
            <div className={styles.dayHeaderDay}>{day}</div>
            <div className={styles.dayHeaderDate}>{date}</div>
        </div>
    )
}

function TaskSchedulerRow({ user }) {
    return (
        <>
            <div className={styles.user}>{user.firstName} {user.lastName}</div>
            <div className={styles.day}>&nbsp;</div>
            <div className={styles.day}>&nbsp;</div>
            <div className={styles.day}>&nbsp;</div>
            <div className={styles.day}>&nbsp;</div>
            <div className={styles.day}>&nbsp;</div>
            <div className={styles.day}>&nbsp;</div>
            <div className={styles.day}>&nbsp;</div>
        </>
    );
}

const data = {
    users: [],
    
}