import React, {useEffect, useState} from 'react';
import './App.css';
import { makeStyles } from '@mui/styles';
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import ListSubheader from "@mui/material/ListSubheader";
import Container from "@mui/material/Container";
import { Routes, Route } from "react-router-dom";
import AddStock from "../inventory/AddStock";
import StockItem from "../inventory/StockItem";
import StockList from "../inventory/StockList";
import StockLocations from "../inventory/locations/StockLocations";
import CssBaseline from "@mui/material/CssBaseline";
import {ApplicationPaths, LoginActions, LogoutActions, QueryParameterNames} from '../auth/ApiAuthorizationConstants';
import {LoginMenu} from "../auth/LoginMenu";
import {ProjectFilesPage} from "../projects/files/ProjectFilesPage";
import PartsPage from "../projects/parts/PartsPage";
import UsersPage from "../users/UsersPage";
import UserPage from "../users/UserPage";
import InviteUserPage from "../users/InviteUserPage";
import {SnackbarContainer} from "../components/ui/snackbar/SnackbarContainer";
import { Link as RouterLink } from 'react-router-dom';
import ProjectsPage from "../projects/ProjectsPage";
import CreateProjectPage from "../projects/CreateProjectPage";
import ProjectPage from "../projects/ProjectPage";
import AssembliesPage from "../projects/assemblies/ProjectAssembliesPage";
import SuppliersPage from "../suppliers/SuppliersPage";
import MaterialTypesPage from "../materials/types/MaterialTypesPage";
import ProjectDirectories from "../settings/project-directories/ProjectDirectories";
import SettingsPage from "../settings/SettingsPage";
import {Login} from "../auth/Login";
import {Logout} from "../auth/Logout";
import SurfaceFinishes from "../materials/surface-finishes/SurfaceFinishesPage";
import EditSurfaceFinishPage from "../materials/surface-finishes/EditSurfaceFinish";
import PartPage from "../projects/part/PartPage";
import QuotationTakeOff from "../quotations/takeoff/QuotationTakeOff";
import Quotation from "../quotations/Quotation";
import EnterProjectWorkLog from "../projects/worklog/EnterProjectWorkLog";
import AuthorizeRoute from "../auth/AuthorizeRoute";
import {useNavigate} from "react-router";
import authService from "../auth/AuthorizeService";
import EditProjectPage from "../projects/EditProjectPage";
import EditWorkDiaryEntry from "../projects/worklog/EditWorkDiaryEntry";
import TaskScheduler from "../scheduler/TaskScheduler";
import ProjectTasksList from "../projects/tasks/ProjectTasksList";
const drawerWidth = 300;



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    list: {
        width: drawerWidth
    }
}));

function App() {
    const classes = useStyles();
    const [state, setState] = React.useState({
        isMenuOpen: false
    });
    
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, isMenuOpen: open });
    };
    
    let closeMenuIfOpen = () => {
        if(state.isMenuOpen) setState({isMenuOpen: false});
    };
    
    return (
        <div className={classes.root} onClick={closeMenuIfOpen}>
            <SnackbarContainer/>
            <CssBaseline>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            size="large">
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Eastern Suburbs Steel Fabrication
                        </Typography>
                        <LoginMenu>
                        </LoginMenu>
                    </Toolbar>
                </AppBar>
                <Drawer anchor={'left'} open={state.isMenuOpen} onClose={toggleDrawer(false)}>
                    <List className={classes.list}>
                        <ListItem component={RouterLink} to={"/"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Home" />
                        </ListItem>
                        <ListItem component={RouterLink} to={"/stock"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Stock List" />
                        </ListItem>
                        <ListItem component={RouterLink} to={"/projects"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Projects" />
                        </ListItem>
                        <ListItem component={RouterLink} to={"/suppliers"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Suppliers" />
                        </ListItem>
                        <ListItem component={RouterLink} to={"/scheduler"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Scheduler" />
                        </ListItem>
                        <Divider />
                        <ListSubheader>Materials</ListSubheader>
                        <ListItem component={RouterLink}  to={"/materials/types"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Types"/>
                        </ListItem>
                        <ListItem component={RouterLink}  to={"/materials/surface-finishes"}>
                            <ListItemIcon><HomeIcon/></ListItemIcon>
                            <ListItemText primary="Surface Finishes"/>
                        </ListItem>
                        <ListSubheader>Settings</ListSubheader>
                        <ListItem component={RouterLink}  to={"/settings"}>
                            <ListItemIcon><SettingsIcon/></ListItemIcon>
                            <ListItemText primary="Settings"/>
                        </ListItem>                            
                    </List>
                </Drawer>
                <Container>
                    <Routes>
                        
                        <AuthorizeRoute path="/quotations/1" element={<Quotation/>} />
                        <AuthorizeRoute path="/quotations/1" element={<Quotation/>} />
                        <AuthorizeRoute path="/quotations/1/takeoff/1" element={<QuotationTakeOff/>} />
                        <AuthorizeRoute path="/materials/surface-finishes/:id" element={<EditSurfaceFinishPage/>}/>
                        <AuthorizeRoute path="/materials/surface-finishes" element={<SurfaceFinishes/>}/>
                        <AuthorizeRoute path="/materials/types" element={<MaterialTypesPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId/worklog/:id/edit" element={<EditWorkDiaryEntry/>}/>
                        <AuthorizeRoute path="/projects/:projectId/worklog/new-entry" element={<EnterProjectWorkLog/>}/>
                        <AuthorizeRoute path="/projects/:projectId/edit" element={<EditProjectPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId/files" element={<ProjectFilesPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId/parts" element={<PartsPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId/parts/:partId/*" element={<PartPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId/assemblies" element={<AssembliesPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId/tasks" element={<ProjectTasksList/>}/>
                        <AuthorizeRoute path="/projects" element={<ProjectsPage/>}/>
                        <AuthorizeRoute path="/projects/create" element={<CreateProjectPage/>}/>
                        <AuthorizeRoute path="/projects/:projectId" element={<ProjectPage/>}/>
                        <AuthorizeRoute path="/users" element={<UsersPage/>}/>
                        <AuthorizeRoute path="/users/invite" element={<InviteUserPage/>}/>
                        <AuthorizeRoute path="/users/:userId" element={<UserPage/>}/>
                        <AuthorizeRoute path="/stock-add" element={<AddStock/>}/>
                        <AuthorizeRoute path="/stock/:id" element={<StockItem/>}/>
                        <AuthorizeRoute path="/stock" element={<StockList/>}/>
                        <AuthorizeRoute path="/settings/stock-locations" element={<StockLocations/>}/>
                        <AuthorizeRoute path="/settings/project-directories" element={<ProjectDirectories/>}/>
                        <AuthorizeRoute path="/settings" element={<SettingsPage/>}/>
                        <AuthorizeRoute path="/" element={<StockList/>}/>
                        <AuthorizeRoute path="/suppliers" element={<SuppliersPage/>}/>
                        <Route path="/authentication*">
                            <Route path="/login" element={<Login action={LoginActions.Login}/>} />
                            <Route path="/login-failed" element={<Login action={LoginActions.LoginFailed}/>} />
                            <Route path="/login-callback" element={<Login action={LoginActions.LoginCallback}/>} />
                            <Route path="/profile" element={<Login action={LoginActions.Profile}/>} />
                            <Route path="/register" element={<Login action={LoginActions.Register}/>} />
                            <Route path="/logout" element={<Logout action={LogoutActions.Logout}/>}/>
                            <Route path="/logout-callback" element={<Logout action={LogoutActions.LogoutCallback}/>} />
                            <Route path="/logged-out" element={<Logout action={LogoutActions.LoggedOut}/>} />
                        </Route>
                    </Routes>
                </Container>
                <Routes>
                    <AuthorizeRoute path="/scheduler" element={<TaskScheduler/>} />
                </Routes>
            </CssBaseline>
        </div>
    );
}

export default App;
