import React, {useEffect, useState} from 'react';
import authService from "../auth/AuthorizeService";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link} from "@mui/material";
import Button from "@mui/material/Button";
import {Link as RouterLink, useParams, useNavigate} from 'react-router-dom';
import {snackbarService} from "../components/ui/snackbar/SnackbarService";
import PageLoadingIndicator from "../components/ui/PageLoadingIndicator";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
export default function UserPage() 
{
    let [user, setUser] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        isAdmin: false
    });
    let [loading, setLoading] = useState(true);
    let [editing, setEditing] = useState(false);

    let [isAdmin, setIsAdmin] = useState(false);

    let {userId} = useParams();
    let navigate = useNavigate();
    
    useEffect(() => {
        const isAdmin = async() => {
            const result = await authService.userInRole('Administrator');
            setIsAdmin(result);
        };

        isAdmin();
    }, []);
    
    useEffect(() => {
        const fetchUser = async() => {
            const token = await authService.getAccessToken();
            const request = await fetch(`/api/users/${userId}`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            let response = await request.json();
            setUser(response);
            setLoading(false);
        };

        fetchUser();
    }, [userId]);
    
    let navigateToUsersPage = () => {
        navigate('/users');
    };
    
    let  save = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        let headers = { 'Content-Type': 'application/json'};
        if(token) headers['Authorization'] =  `Bearer ${token}`;
        const request = await fetch(`/api/users/${user.id}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(user)
        });
        try{
            let responseOk = request.status === 200;
            if(!responseOk) {
                snackbarService.showSnackbar(`There was an error while updating the user`, 'error');
            } else {
                snackbarService.showSnackbar(`User's details were updated successfully`, 'success');
                navigateToUsersPage();
            }
        } catch {
            setLoading(false);
        }
    }

    let disableUser = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        let headers = { 'Content-Type': 'application/json'};
        if(token) headers['Authorization'] =  `Bearer ${token}`;
        const request = await fetch(`/api/users/${user.id}`, {
            method: 'DELETE',
            headers: headers,
        });
        try{
            let responseOk = request.status === 200;
            if(!responseOk) {
                snackbarService.showSnackbar(`There was an error while disabling the user`, 'error');
            } else {
                snackbarService.showSnackbar(`The user account has been disabled`, 'success');
                navigateToUsersPage();
            }
        } catch {
            setLoading(false);
        }
    }

    let enableUser = async () => {
        setLoading(true);
        const token = await authService.getAccessToken();
        let headers = { 'Content-Type': 'application/json'};
        if(token) headers['Authorization'] =  `Bearer ${token}`;
        const request = await fetch(`/api/users/${user.id}/enable`, {
            method: 'POST',
            headers: headers,
        });
        try{
            let responseOk = request.status === 200;
            if(!responseOk) {
                snackbarService.showSnackbar(`There was an error enabling the user`, 'error');
            } else {
                snackbarService.showSnackbar(`The user account has been enabled`, 'success');
                navigateToUsersPage();
            }
        } catch {
            setLoading(false);
        }
    }

    return (
        <Grid container>
            <PageLoadingIndicator loading={loading}/>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} color="inherit" to="/">
                        Home
                    </Link>
                    <Link  component={RouterLink} color="inherit"  to={"/users"}>Users</Link>
                    <Typography >{user.firstName} {user.lastName}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}  md={7} style={{'paddingTop': 16}}>
                {isAdmin && !editing && <Button style={{float: 'right', marginLeft: '16px'}}  variant="contained" color="primary" onClick={() => setEditing(true)}>Edit</Button>}
                {isAdmin && user.isActive && <Button style={{float: 'right'}}  variant="outlined" color="secondary" onClick={disableUser}>Disable User Account</Button>}
                {isAdmin && !user.isActive && <Button style={{float: 'right'}}  variant="outlined" color="primary" onClick={enableUser}>Enable User Account</Button>}

                <Typography variant={"h5"}>{user.firstName} {user.lastName}</Typography>
            </Grid>
            <Grid item xs={12} md={7} style={{'paddingTop': 16}}>
                <form>
                    <FormControlLabel disabled={!editing}
                        control={<Checkbox checked={user.isAdmin}  onChange={(e) => setUser({ ...user, isAdmin: e.target.checked })} name="isAdministrator" />}
                        label="Administrator"
                    />
                    <TextField
                        disabled={!editing}
                        autoFocus
                        id="firstName"
                        label="First Name"
                        type="text"
                        fullWidth
                        value={user.firstName}
                        onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                    />
                    <TextField
                        disabled={!editing}
                        id="lastName"
                        label="Last Name"
                        type="text"
                        fullWidth
                        value={user.lastName}
                        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                    />
                    <TextField
                        disabled={!editing}
                        id="emailAddress"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={user.emailAddress}
                        onChange={(e) => setUser({ ...user, emailAddress: e.target.value })}
                    />
                    <TextField
                        disabled={!editing}
                        id="phoneNumber"
                        label="Phone Number"
                        type="text"
                        fullWidth
                        value={user.phoneNumber}
                        onChange={(e) => setUser({ ...user, phoneNumber: e.target.value })}
                    />
                    
                    {editing && <Button style={{float: 'right', marginTop: '16px'}}  variant="contained" color="primary" onClick={save}>Save</Button>}
                </form>
            </Grid>
        </Grid>

    )
}