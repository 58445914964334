import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {ProjectAssemblyService, ProjectsService} from "../../api/ApiService";
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import AssemblyList from "./AssemblyList";
import {Link as RouterLink} from "react-router-dom";

const assemblyService = new ProjectAssemblyService();
const projectService = new ProjectsService();

export default function AssembliesPage() {
    let { projectId } = useParams();
    let [assemblies, setAssemblies] = useState([]);
    
    useEffect(() => {
        const loadAssembles = async () => {
            let results = await assemblyService.getAssemblies(projectId);
            setAssemblies(results);
        }
        
        loadAssembles();
    }, [projectId]);

    let [project, setProject] = useState({ name: ''});
    useEffect(() => {
        async function loadProject() {
            let projectRequest = await projectService.getProject(projectId);
            setProject(projectRequest);
        }
        loadProject();
    }, [projectId]);
    return (
        <Grid container>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <RouterLink color="inherit" to="/">
                        Home
                    </RouterLink>
                    <RouterLink color="inherit" to={"/projects"}>
                        Projects
                    </RouterLink>
                    <RouterLink color="inherit" to={`/projects/${projectId}`}>
                        JB{project.number} - {project.name}
                    </RouterLink>
                    <Typography color="textPrimary">Assemblies</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                {/*<Button style={{float: 'right'}}  variant="contained" color="primary" onClick={handleImportParts}>Import Parts</Button>*/}
                <Typography variant="h6">{'RU Data 66 / Priority 9 - Awning'.toUpperCase()}</Typography>
                <Typography variant="h5">ASSEMBLIES</Typography>
            </Grid>
            <Grid item xs={12}>
                <AssemblyList assemblies={assemblies}/>
            </Grid>
            {/*<input type="file" ref={importFileRef} hidden onChange={handleImportPartsFile} />*/}
        </Grid>
    )
}