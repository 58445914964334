import React, { useEffect, useState} from 'react';
import authService from "../auth/AuthorizeService";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link} from "@mui/material";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import {UserService} from "../api/ApiService";

const useStyles = makeStyles({
    tableRow: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});
 
const userService = new UserService();

export default function UsersPage(props) 
{
    let navigate = useNavigate();
    let [users, setUsers] = useState([]);
    useEffect( () =>  {
        let loadUsers = async () => {
            let response = await userService.getUsers(false);
            setUsers(response.users);
        };
        loadUsers();    
    }, []);

    let [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const isAdmin = async() => {
            const result = await authService.userInRole('Administrator');
            setIsAdmin(result);
        };

        isAdmin();
    }, []);
    
    let navigateToUserPage = (userId) => {
        navigate('/users/' + userId);
    };
    
    const classes = useStyles();
    
    return (
        <Grid container>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">
                        Home
                    </Link>
                    <Typography color="textPrimary">Users</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                {isAdmin && <Button component={RouterLink} style={{float: 'right'}}  variant="contained" color="primary" to="/users/invite">Invite user</Button>}
                <Typography variant={"h5"}>Users</Typography>
            </Grid>
            <Grid item xs={12}  style={{'paddingTop': 16}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant={"button"}>Name</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Phone Number</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Email</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Status</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user =>
                            <TableRow key={user.id} hover={true} onClick={() => navigateToUserPage(user.id)} className={classes.tableRow}>
                                <TableCell>{user.firstName} {user.lastName}</TableCell>
                                <TableCell>{user.phoneNumber}</TableCell>
                                <TableCell>{user.emailAddress}</TableCell>
                                <TableCell>{!user.isActive ? 'Disabled' : user.emailConfirmed ? 'Active' : 'Invited'}</TableCell>
                            </TableRow>)}
                    </TableBody>

                </Table>
            </Grid>
        </Grid>
        
    )
}