import React, {useEffect, useState} from 'react';
import DataTable from "../components/ui/data-table/DataTable";
import {SuppliersService} from "../api/ApiService";

const supplierService = new SuppliersService();

export default function SuppliersPage() {
    let [suppliers, setSuppliers] = useState([]);
    useEffect(() => {
        const loadSuppliers = async () => {
            let response = await supplierService.get();
            setSuppliers(response);
        }    
        loadSuppliers();
    }, []);
    
    const columns = [
        {
            name: 'Name',
            property: 'name'
        }
    ];
    
    return <DataTable key={'id'} columns={columns} data={suppliers}/>
}
