import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import Paper from "@mui/material/Paper";
import {SurfaceFinishService} from "../../api/ApiService";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {FormControlLabel, Switch, TableCell} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {snackbarService} from "../../components/ui/snackbar/SnackbarService";
import makeStyles from "@mui/styles/makeStyles";
const surfaceFinishService = new SurfaceFinishService();
export default function EditSurfaceFinishPage() 
{
    let params = useParams();
    let navigate = useNavigate();
    let surfaceFinishId = params.id;
    let creating = surfaceFinishId === 'create';
    let [surfaceFinish, setSurfaceFinish] = useState({ id: null, name: '', code: '', isColoured: true, colours: []});
    let [colours, setColours] = useState([]);
    let [newColour, setNewColour] = useState(false);

    const getSurfaceFinish = async () => {
        let surfaceFinishes = await surfaceFinishService.get();
        let surfaceFinish = surfaceFinishes.filter(i => i.id === surfaceFinishId)[0];
        setSurfaceFinish(surfaceFinish);
        setColours(surfaceFinish.colours);
        console.log(surfaceFinish);
    }
    
    useEffect(() => {
        if(creating) return;
        
        getSurfaceFinish();
    }, [surfaceFinishId]);
    
    const handleSave = async () => {
        let result = await surfaceFinishService.create(surfaceFinish.code, surfaceFinish.name, colours, surfaceFinish.isColoured);
        console.log(result);
        navigate("../" + result);
    }
    
    const onCodeChange = (e) => {
        setSurfaceFinish({
            ...surfaceFinish, 
            code: e.target.value
        })
    }

    const onNameChange = (e) => {
        setSurfaceFinish({
            ...surfaceFinish,
            name: e.target.value
        })
    }
    
    const onIsColouredChange = (e) => {
        setSurfaceFinish({
            ...surfaceFinish,
            isColoured: e.target.checked
        });
    }
    
    const newColor = (val) => {
        setNewColour(val);
    }
    
    const handleColourUpdated = () => {
        getSurfaceFinish();
    }
    
    const handleColourDeleted = () => {
        getSurfaceFinish();
    }
    
    const newColourData = {
        id: null,
        name: ''
    }
    
    const useStyles = makeStyles({
        root: {
            marginTop: '24px',
           
            paddingBottom: '32px',
            
        },
        header: {
            paddingTop: '16px',
            paddingBottom: '16px',
            paddingLeft: '24px',
            paddingRight: '24px',
            display: "flex",
            marginBottom: '32px',
            borderBottom: '1px solid #ECECF3',
            lineHeight: 1,
            verticalAlign: "bottom",
            alignItems: 'center'
        },
        headerTitle: {
            fontWeight: 500,
            fontSize: '18px',
            flexGrow: 1,
            letterSpacing: '-0.5px'
        },
        headerActions: {
            
        }
    })
    
    const styles = useStyles();
    
    return (
        <Paper elevation={24} className={styles.root}>
            <div className={styles.header}>
                <div className={styles.headerTitle}>
                    <span>Edit Surface Finish</span>
                </div>
                <div className={styles.headerActions}>
                    <Button variant={"contained"} onClick={handleSave}>Create Surface Finish</Button>
                </div>
            </div>
            <form>
            <TextField
                autoFocus
                id="code"
                label="Code"
                type="text"
                onChange={onCodeChange}
                value={surfaceFinish.code}
            />
                <TextField
                
                id="name"
                label="Name"
                type="text"
                fullWidth
                onChange={onNameChange}
                value={surfaceFinish.name}
            />
                
            </form>
            <FormControlLabel
                control={
                    <Switch
                        checked={surfaceFinish.isColoured}
                        onChange={onIsColouredChange}
                        name="isColoured"
                        color="primary"
                    />
                }
                label="This surface finish has colour options"
            />
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Colour</TableCell>
                        <TableCell><Button onClick={() => newColor(true)}>New Colour</Button></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {newColour && <ColourRow colour={newColourData} surfaceFinishId={surfaceFinish.id} onDeleted={() => newColor(false)} onUpdated={() => {newColor(false); handleColourUpdated();}}/>}
                    {colours.map(c =>
                        <ColourRow colour={c} surfaceFinishId={surfaceFinish.id} onDeleted={handleColourDeleted} onUpdated={handleColourUpdated}/>
                    )}
                </TableBody>
            </Table>
        </Paper>
    )
}

function ColourRow({surfaceFinishId, colour, onDeleted, onUpdated}) {
    let [isEditing, setIsEditing] = useState(false);
    let [name, setName] = useState();
    
    useEffect(() => {
        setName(colour.name);
        if(!colour.id) setIsEditing(true); 
    }, [colour])
    
    
    const onEdit = () => {
        setIsEditing(true);
    }
    
    const onDelete = async () => {
        try {
            await surfaceFinishService.deleteColour(colour.id);
            onDeleted();            
        } catch(err) {
            await snackbarService.showSnackbar(`There was an error deleting the colour`, 'error');
        }
    }
    
    const onSave = async () => {
        if(!colour.id) {
            try {
                console.log(surfaceFinishId);
                await surfaceFinishService.createColour(surfaceFinishId, name);
                onUpdated();
                setIsEditing(false);
            } catch(err) {
                await snackbarService.showSnackbar(`There was an error adding the colour`, 'error');
            }
        } else {
            try {
                await surfaceFinishService.updateColour(colour.id, name);
                onUpdated();
                setIsEditing(false);
            } catch(err) {
                await snackbarService.showSnackbar(`There was an error updating the colour`, 'error');
            }
        }
    }
    
    const onCancel = async () => {
        if(!colour.id) {
            onDeleted();
            return;
        }
        setName(colour.name);
        setIsEditing(false);
    }
    
    const onNameChange = (e) => {
        setName(e.target.value);
    }
    let result;
    
    if(isEditing) {
        result = 
            <TableRow>
                <TableCell>
                    <TextField
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        onChange={onNameChange}
                        value={name}
                    />
                </TableCell>
                <TableCell>
                    <Button onClick={onSave}>Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </TableCell> 
            </TableRow>
    } else {
        result = 
            <TableRow>
                <TableCell>{colour.name}</TableCell>
                <TableCell>
                    <Button onClick={onEdit}>Edit</Button>
                    <Button onClick={onDelete}>Delete</Button>
                </TableCell>
            </TableRow>
    }
    
    return (
        <>
        {result}
        </>
    );
}