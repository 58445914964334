import React, {useEffect, useState} from 'react';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import authService from "../../auth/AuthorizeService";
import MenuItem from "@mui/material/MenuItem";

export default function ProjectStatusSelect({onStatusChange, value, ...props}) 
{
    let [statuses, setStatuses] = useState([]);

    useEffect(() => {
        const fetchProjectStatuses = async() => {
            const token = await authService.getAccessToken();
            const request = await fetch(`/api/projects/statuses`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
            });
            let response = await request.json();
            setStatuses(response); 
        };
        
        fetchProjectStatuses();
    }, []);
    
    return (
        <FormControl fullWidth size={"small"}>
            <InputLabel>Project Status</InputLabel>
            <Select
                size={"small"}
                
                label={"Project Status"}
                    onChange={(e) => onStatusChange(e.target.value)} value={value}
            >
                {statuses.map(item => (
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}