import React from 'react';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {InputLabel, MenuItem} from "@mui/material";

export default function MaterialTypeList({ onMaterialTypeChange, materialTypeIdSelected, materialTypeList }) {
    materialTypeList.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true}));
    return (
        <FormControl sx={{width: '100%', marginTop: '24px'}}>
            <InputLabel>Material Type</InputLabel>
            <Select fullWidth={true}
                label="Material Type"
                onChange={e => onMaterialTypeChange(e.target.value)} value={materialTypeIdSelected}
            >
                <MenuItem value={""}>Any</MenuItem>
                        {materialTypeList.map(item => (
                            <MenuItem  key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
            </Select>
        </FormControl>
    );
}