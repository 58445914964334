import React from "react";
import styles from './PartsList.module.css'
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

export default function PartsListItemStatusLine({partStatusLine}) {
    return (
        <TableRow>
            <TableCell>
                <Checkbox color="primary" />
            </TableCell>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell/>
            <TableCell>{partStatusLine.statusCode}</TableCell>
            <TableCell className={styles.qty}>{partStatusLine.qty}</TableCell>
            <TableCell/>
            <TableCell/>
            <TableCell className={styles.action}>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" size={"small"}>
                    <MoreVertIcon/>
                </IconButton>
            </TableCell>
        </TableRow>
    )
}