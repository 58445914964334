import React from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import { Fragment, useState, useEffect } from 'react';

export default function DataTable({ key, columns, data }) {
    let [tableData, setTableData] = useState(data);
    
    useEffect(() => {
        setTableData(data.map(d => {
            return {
                ...d,
                isExpanded: false
            }
        }))
    }, [data]);
    
    const toggleExpanded = (key) => {
        console.log(key);
        console.log(tableData);
        setTableData(state => {
            return state.map(i => {
                if(i.key === key) {
                    return {
                        ...i,
                        isExpanded: !i.isExpanded
                    } 
                } else {
                    return i;
                }
            });
        });
    }
    
    return (
        <Table>
            <TableHead>
                <TableRow>
                    {columns.map(m => <TableCell>{m.name}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map(row => (
                    <DataTableExpandRowGroup key={row[key]}>
                        <DataTableParentRow onExpand={() => toggleExpanded(row[key])}>

                        </DataTableParentRow>
                        <DataTableChildRow isExpanded={row.isExpanded}>

                        </DataTableChildRow>
                    </DataTableExpandRowGroup>
                ))}
            </TableBody>
        </Table>
    )
}

function DataTableExpandRowGroup({children}) {
    return (
        <Fragment>
            {children}
        </Fragment>
    );
}

function DataTableParentRow({ onExpand }) {
    return (
    <TableRow onClick={onExpand}>
        <TableCell>Hello</TableCell>
    </TableRow>
    );
}

function DataTableChildRow() {
    return (
        <TableRow>
            <TableCell>Hello</TableCell>
        </TableRow>
    );
}