import React, {useEffect, useState} from 'react';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import {snackbarService} from "../components/ui/snackbar/SnackbarService";
import PageLoadingIndicator from "../components/ui/PageLoadingIndicator";
import GoogleMaps from "../components/ui/GoogleMapsAutoComplete";
import InputAdornment from "@mui/material/InputAdornment";
import { ProjectsService } from "../api/ApiService";
import ContactSelect from "../components/ContactSelect";
import {useParams} from "react-router";

const projectsService = new ProjectsService();

export default function EditProjectPage() 
{
    let { projectId } = useParams();
    let [errors, setErrors] = useState([]);
    let navigate = useNavigate();
    let [project, setProject] = useState({
        name: '',
        statusId: '',
        number: ''
    });
    let [address, setAddress] = useState({
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postCode: '',
        country: '',
        googlePlaceId: '',
        latitude: null,
        longitude: null
    });
    
    useEffect(() => {
        async function loadProject(projectId) {
            let result = await projectsService.getProject(projectId);
            setProject({
                name: result.name,
                number: result.number
            });
            setProjectDetails(result.details);
            setCustomer(result.customer != null ? {
                id: result.customer.id,
                name: result.customer.name
            } : { id: '', name: ''});

            setAddress({
                addressLine1: result.address.line1,
                addressLine2: result.address.line2,
                city: result.address.suburb,
                state: result.address.state,
                postCode: result.address.postCode,
                country: result.address.country,
                googlePlaceId: result.address.googlePlaceId,
                latitude: null,
                longitude: null
            });
            setLoading(false);
        }
        
        setLoading(true);
        loadProject(projectId);
        
    }, [projectId]);
    
    let [customer, setCustomer] = useState({ id: '', name: ''});
    let [loading, setLoading] = useState(false);
    let [manualAddress, setManualAddress] = useState(true);
    let [projectDetails, setProjectDetails] = useState('');
    
    let navigateToProjectPage = (projectId) => {
        console.log(projectId);
        navigate('/projects/' + projectId);
    };
    
    let  saveProject = async () => {
        setErrors([]);
        let request = {
            id: projectId,
            address: address,
            project: {
                ...project,
                customerId: customer?.id ? customer?.id : null,
            },
            details: projectDetails
        };
        setLoading(true);
        
        try {
            let result = await projectsService.editProject(projectId, request);
            snackbarService.showSnackbar(`The project has been updated`, 'success');
            console.log(result);
            navigateToProjectPage(result.id);
        } catch(ex) {
            if(ex.status === 400) {
                setErrors(ex.response.errors);
                console.dir(ex.response.errors);
            }
            console.log(ex);
            snackbarService.showSnackbar(`There was an error while updating the project`, 'error');
            setLoading(false);
        }
    }

    let handleChangeAddress = (address) => {
        setAddress(address);
        setManualAddress(true);
    };
    
    const handleCustomerChange = (value) => {
        console.log(value);
        setCustomer(value);
    }
    
    const hasError = (field) => {
        return field && field.length > 0;
    }
    
    return (
        <Grid container spacing={2} maxWidth={"md"}>
            <PageLoadingIndicator loading={loading}/>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} color="inherit" to="/">
                        Home
                    </Link>
                    <Link  component={RouterLink} color="inherit"  to={"/projects"}>Projects</Link>
                    <Typography>Edit Project</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12}  style={{'paddingTop': 16}}>
                <Typography  variant={"h6"}>Edit Project Details</Typography>
            </Grid>
            <Grid item xs={12} sm={3} style={{'paddingTop': 16}}>
                <TextField
                    error={hasError(errors["Project.Number"])}
                    helperText={errors["Project.Number"]?.join(',')}
                    autoFocus
                    size={"small"}
                    id="projectNumber"
                    label="Project Number"
                    type="number"
                    fullWidth
                    value={project.number}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">JB-</InputAdornment>,
                    }}
                    onChange={(e) => setProject({ ...project, number: e.target.value })}
                />
            </Grid>
            <Grid item xs={12} sm={9} style={{'paddingTop': 16}}>
                   <TextField
                       size={"small"}
                       error={hasError(errors["Project.Name"])}
                       helperText={hasError(errors["Project.Name"])? errors["Project.Name"]?.join(',') : 'Leave blank to use address as project name'}
                        id="projectName"
                        label="Project Name"
                        type="text"
                        fullWidth
                        value={project.name}
                        onChange={(e) => setProject({ ...project, name: e.target.value })}
                    />
            </Grid>
            <Grid item xs={12}>
                <TextField size={"small"}
                           id="details"
                           label="Details"
                           type="text"
                           fullWidth
                           rows={10}
                           multiline={true}
                           value={projectDetails}
                           onChange={(e) => setProjectDetails(e.target.value)}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ContactSelect contact={customer} label={"Customer"} onChange={handleCustomerChange} />
            </Grid>
            
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <div style={{display:'flex', flexDirection: 'row-reverse', paddingBottom: 16}}>
                    <Button  variant="outlined" color="primary" onClick={() => setManualAddress(!manualAddress)}>{manualAddress ? 'Search for address' : 'Enter Adress Manually'}</Button>
                    <Typography variant={"h6"} style={{flexGrow: 1}}>Address</Typography>
                </div>
                {!manualAddress && <GoogleMaps onChangeAddress={handleChangeAddress}/>}
                {manualAddress && 
                <Grid spacing={2} container>
                    <Grid item xs={12}>
                        <TextField
                            size={"small"}
                            id="addressLine1"
                            label="Address Line 1"
                            type="text"
                            fullWidth
                            value={address.addressLine1}
                            onChange={(e) => setAddress({ ...address, addressLine1: e.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        size={"small"}
                        id="addressLine2"
                        label="Address Line 2"
                        type="text"
                        fullWidth
                        value={address.addressLine2}
                        onChange={(e) => setAddress({ ...address, addressLine2: e.target.value })}
                    />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                    <TextField
                        size={"small"}
                        id="city"
                        label="City"
                        type="text"
                        fullWidth
                        value={address.city}
                        onChange={(e) => setAddress({ ...address, city: e.target.value })}
                    />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                    <TextField
                        size={"small"}
                        id="postCode"
                        label="Post Code"
                        type="text"
                        fullWidth
                        value={address.postCode}
                        onChange={(e) => setAddress({ ...address, postCode: e.target.value })}
                    />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                    <TextField
                        size={"small"}
                        id="state"
                        label="State"
                        type="text"
                        fullWidth
                        value={address.state}
                        onChange={(e) => setAddress({ ...address, state: e.target.value })}
                    />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size={"small"}
                            id="country"
                            label="Country"
                            type="text"
                            fullWidth
                            value={address.country}
                            onChange={(e) => setAddress({ ...address, country: e.target.value })}
                        />
                    </Grid>
                </Grid>
                }
            </Grid>
            
            
            <Grid  item xs={12} style={{'paddingTop': 16}}>
                <Button style={{float: 'right', marginTop: '16px'}}  variant="contained" color="primary" onClick={() => saveProject()}>Save Changes</Button>

            </Grid>
        </Grid>
        
    )
}