import React, { useState, Fragment, useEffect } from 'react';
import {Breadcrumbs, Link, TableHead} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import StockLocationList from "../components/inventory/locations/StockLocationList";
import MaterialList from "../components/materials/MaterialList";
import MaterialProfileList from "../components/materials/MaterialProfileList";
import MaterialTypeList from "../components/materials/MaterialTypeList";
import DistanceMeasurement from "../components/DistanceMeasurement";
import WeightMeasurement from "../components/WeightMeasurement";
type StockListProps = {
    
} 

type StockItemResult = {
    id: string,
    code: string,
    qty: number,
    stockLocationId: string,
    length: number,
    materialProfileId: string,
    materialId: string,
    weight: number
}

type Material = {
    id: string,
    code: string,
    materialProfileId: string
    materialTypeId: string
}

type MaterialType = {
    id: string,
    name: string
}

type StockLocation = {
    id: string,
    code: string,
    parentId: string | null
}

type MaterialProfile = {
    id: string,
    code: string,
    name: string
}

type StockListState = {
    error: { message: string },
    isLoaded: boolean,
    items: StockItemResult[],
    materialLoaded: boolean,
    materialList: Material[],
    materialIdSelected: string,
    materialTypesLoaded: boolean,
    materialTypesList: MaterialType[],
    materialTypeIdSelected: string,
    stockLocationIdSelected: string,
    stockLocationsLoaded: boolean,
    stockLocations: StockLocation[],
    materialProfilesLoaded: boolean,
    materialProfiles: MaterialProfile[],
    materialProfileIdSelected: string 
}

function StockList(props: StockListProps)
{
    let params = new URLSearchParams(useLocation().search);
    let stockLocationId = params.get('stockLocationId');
    let navigate = useNavigate();
    let initialState: StockListState = {
        error: { message: ''},
        isLoaded: false,
        items: [],
        materialLoaded: false,
        materialList: [],
        materialIdSelected: '',
        materialTypesLoaded: false,
        materialTypesList: [],
        materialTypeIdSelected: '',
        stockLocationIdSelected: stockLocationId ? stockLocationId : '',
        stockLocationsLoaded: false,
        materialProfilesLoaded: false,
        materialProfiles: [],
        stockLocations: [],
        materialProfileIdSelected: ''
    };

    let [state, setState] = useState(initialState);
   
   const getCodesFilteredByProfile = () => {
       let results: Material[] = state.materialList;
       if(state.materialProfileIdSelected) results = results.filter(item => item.materialProfileId === state.materialProfileIdSelected);
       if(state.materialTypeIdSelected) results = results.filter(item => item.materialTypeId === state.materialTypeIdSelected);
       return results;
   };

    const updateStockByFilter = (materialId: string, stockLocationId: string, materialProfileId: string, materialTypeId: string) => {
        if(materialTypeId !== state.materialTypeIdSelected && materialTypeId) {
            materialId = '';
            materialProfileId = '';
        }
       if(materialProfileId !== state.materialProfileIdSelected && materialProfileId) materialId = '';
        setState(s => ({
            ...s,
            isLoaded: false,
            items: [],
            materialIdSelected: materialId,
            stockLocationIdSelected: stockLocationId,
            materialProfileIdSelected: materialProfileId,
            materialTypeIdSelected: materialTypeId
        }));

        fetch(`/api/stock?materialId=${materialId}&stockLocationId=${stockLocationId}&materialProfileId=${materialProfileId}&materialTypeId=${materialTypeId}`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(s => ({
                        ...s,
                        isLoaded: true,
                        items: result
                    }));
                },
                (error) => {
                    setState(s => ({
                        ...s,
                        isLoaded: true,
                        error
                    }));
                }
            )
    };

    useEffect(() => {
        fetch(`/api/stock`)
        .then(res => res.json())
        .then(
            (result) => {
                setState(s => ({
                    ...s,
                    isLoaded: true,
                    items: result
                }));
            },
            (error) => {
                setState(s => ({
                    ...s,
                    isLoaded: true,
                    error
                }));
            }
        );

        fetch(`/api/material`)
        .then(res => res.json())
        .then(
            (result) => {
                setState(s => ({
                    ...s,
                    materialLoaded: true,
                    materialList: result
                }));
            }
        );

        fetch(`/api/material-types`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(s => ({
                        ...s,
                        materialTypesLoaded: true,
                        materialTypesList: result
                    }));
                }
            );

        fetch(`/api/stock-locations`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(s => ({
                        ...s,
                        stockLocationsLoaded: true,
                        stockLocations: result
                    }));
                }
            );

        fetch(`/api/material-profiles`)
            .then(res => res.json())
            .then(
                (result) => {
                    setState(s => ({
                        ...s,
                        materialProfilesLoaded: true,
                        materialProfiles: result
                    }));
                }
            )
    }, []);
        

    const navigateToStockItem = (id: string) => {
        navigate(`/stock/${id}`);
    };
    
    const getStockLocationCode = (id: string): string => {
        let code = '';
        let stockLocation = state.stockLocations.filter((i: StockLocation) => i.id === id);
        if(stockLocation.length === 1) {
            code = stockLocation[0].code;
            if(stockLocation[0].parentId !== null) {
                return getStockLocationCode(stockLocation[0].parentId) + '-' + code;
            }
        }
        return code;
    };

    let totals = { weight: 0, qty: 0};
    if(state && state.items && state.items.length > 0) {
        totals = state.items.reduce((prev,current) => {
            prev.qty = prev.qty + current.qty;
            prev.weight = prev.weight + (current.qty * current.weight);
            return prev;
        },{weight: 0, qty: 0});
    }
    
    const { error, isLoaded, items } = state;
    if (error.message.length > 0) {
            return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
    return <div>Loading...</div>;
    } else {
        return (
            <Grid container>
                <Grid item xs={12} style={{'paddingTop': 16}}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link component={RouterLink} color="inherit" to="/">
                            Home
                        </Link>
                        <Typography color="textPrimary">Stock List</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} style={{'paddingTop': 16}}>
                    <Button style={{float: 'right'}}  variant="contained" color="primary" component={RouterLink} to="/stock-add">Add Stock</Button>
                    <Typography variant={"h5"}>Stock List</Typography>
                </Grid>
                <Grid item xs={12}  style={{'paddingTop': 16}}>
                    
                <Paper elevation={1} style={{padding: '16px'}}>
                    <Typography variant={"button"}>Filters</Typography>
                    {state.materialTypesLoaded && <MaterialTypeList materialTypeIdSelected={state.materialTypeIdSelected} materialTypeList={state.materialTypesList} onMaterialTypeChange={(val: string) => updateStockByFilter(state.materialIdSelected, state.stockLocationIdSelected, state.materialProfileIdSelected, val)}/>}
                    {state.materialProfilesLoaded && <MaterialProfileList materialProfileIdSelected={state.materialProfileIdSelected} materialProfileList={state.materialProfiles} onMaterialProfileChange={(val: string) => {console.log(val); updateStockByFilter(state.materialIdSelected, state.stockLocationIdSelected, val, state.materialTypeIdSelected)}}/>}
                    {state.materialLoaded && <MaterialList materialIdSelected={state.materialIdSelected} materialList={getCodesFilteredByProfile()} onMaterialChange={(val: string) => updateStockByFilter(val, state.stockLocationIdSelected, state.materialProfileIdSelected, state.materialTypeIdSelected)}/>}
                    {state.stockLocationsLoaded && <StockLocationList stockLocationIdSelected={state.stockLocationIdSelected} stockLocationList={state.stockLocations} onStockLocationChange={(val: string) => updateStockByFilter(state.materialIdSelected, val, state.materialProfileIdSelected, state.materialTypeIdSelected)}/>}
                </Paper>
                    
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant={"button"}>Code</Typography></TableCell>
                                <TableCell align={"right"}><Typography variant={"button"}>Length</Typography></TableCell>
                                <TableCell align={"right"}><Typography variant={"button"}>Qty</Typography></TableCell>
                                <TableCell align={"right"}><Typography variant={"button"}>Weight</Typography></TableCell>
                                <TableCell><Typography variant={"button"}>Location</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.length === 0 &&
                                <TableRow><TableCell colSpan={5}>No stock found</TableCell></TableRow>}
                            {
                                state.materialProfiles.map((materialProfile) => {
                                    let itemsForMaterialProfile = items.filter((item) => {
                                        return item.materialProfileId === materialProfile.id
                                    }).sort((a,b) => {
                                        let res = a.code.localeCompare(b.code, 'en', { numeric: true });
                                        if(res === 0) return a.length - b.length;
                                        return res;
                                    }
                                        );
                                    let weightAndQty = itemsForMaterialProfile.reduce((prev,current) => {
                                        prev.qty = prev.qty + current.qty;
                                        prev.weight = prev.weight + (current.qty * current.weight);
                                        return prev;
                                    },{weight: 0, qty: 0});
                                    if(itemsForMaterialProfile.length > 0) {
                                        return (
                                            <Fragment key={materialProfile.id}>
                                            <TableRow  style={{'backgroundColor': '#eee'}}>
                                                <TableCell colSpan={2}><Typography variant={"subtitle2"}>{materialProfile.code} ({materialProfile.name})</Typography></TableCell>
                                                <TableCell align={"right"}><Typography variant={"subtitle2"}>{weightAndQty.qty}</Typography></TableCell>
                                                <TableCell align={"right"}><Typography variant={"subtitle2"}><WeightMeasurement value={weightAndQty.weight/100}/></Typography></TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            {itemsForMaterialProfile.map((item: StockItemResult) => (
                                                <TableRow hover key={item.id} onClick={() => navigateToStockItem(item.id)}>
                                                    <TableCell><Typography variant={"body2"}>{item.code}</Typography></TableCell>
                                                    <TableCell align={"right"}><Typography variant={"body2"}><DistanceMeasurement value={item.length}/></Typography></TableCell>
                                                    <TableCell align={"right"}><Typography variant={"body2"}>{item.qty}</Typography></TableCell>
                                                    <TableCell align={"right"}><Typography variant={"body2"}><WeightMeasurement value={item.weight/100}/></Typography></TableCell>
                                                    <TableCell><Typography variant={"body2"}>{getStockLocationCode(item.stockLocationId)}</Typography></TableCell>
                                                </TableRow>
                                            ))}
                                        </Fragment>
                                            )
                                        
                                    }
                                    else {
                                        return null;
                                    }
                                    
                                })
                            }
                            <TableRow style={{'backgroundColor': '#eee'}}>
                                <TableCell colSpan={2}><Typography variant={"subtitle2"} align={"right"}>Total</Typography></TableCell>
                                <TableCell><Typography variant={"subtitle2"} align={"right"}>{totals.qty}</Typography></TableCell>
                                <TableCell><Typography variant={"subtitle2"} align={"right"}>{totals.weight/100}</Typography></TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
        );
    }
}

export default StockList;