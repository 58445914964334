import React from 'react';
import {Link as RouterLink} from "react-router-dom";

export default function SettingsPage() {
    return (
        <div>
            <h1>Settings</h1>
            <div>
                <h2>Project Settings</h2>
                <RouterLink to={"project-directories"}>Default Directories</RouterLink>
            </div>
            <div>
                <h2>User Administration</h2>
                <RouterLink to={"/users"}>Users</RouterLink>
            </div>
            <div>
                <h2>Inventory</h2>
                <RouterLink to={"stock-locations"}>Stock Location</RouterLink>
            </div>
        </div>
    )
}