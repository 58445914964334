import React from 'react';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {MenuItem, TextField} from "@mui/material";
import {Autocomplete} from "@mui/lab";
import {matchSorter} from "match-sorter";

let matchSorterOptions = {
    
}

export default function MaterialList({ materialList, onMaterialChange, materialIdSelected}) {
    materialList = materialList.sort((a, b) => a.code.localeCompare(b.code, 'en', {numeric: true})).map(i => {
        return { id: i.id, label: i.code + ' ' + i.name};
    });
    
    let materialSelectedValue = '';

    if(materialIdSelected) {
        materialSelectedValue = materialList.filter(i => i.id === materialIdSelected)[0];
    }
    let handleChange = (event, newValue) => {
        //let materialProfile = materialProfileList.filter(i => i.label === event.target.)
        newValue = newValue == null ? '' : newValue.id;
        onMaterialChange(newValue);
    }

    let isOptionEqualToValue = (option, value) => {
        return option.label === value;
    }
    
    let filterOptions = (options, { inputValue}) => {
        return matchSorter(options, inputValue, {keys: ['label'], threshold: matchSorter.rankings.MATCHES});
    };
    
    return (
        <Autocomplete
            label={"Profile Type"}
            fullWidth={true}
            sx={{marginTop: '24px'}}
            options={materialList}
            renderInput={(params) => <TextField {...params} label="Profile" />}
            onChange={handleChange} value={materialSelectedValue.label}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={filterOptions}
        />
    )
    
    /*return (
            <FormControl style={{width: '100%'}}>
                <Select label={"Code"}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={(e) => onMaterialChange(e.target.value)} value={materialIdSelected}
                >
                    <MenuItem value={""}>Any </MenuItem>
                    {materialList.map(item => (
                        <MenuItem key={item.id} value={item.id}>{item.code}</MenuItem>
                    ))}
                </Select>
            </FormControl>
    );*/
}