import React, {Fragment} from "react";
import Checkbox from "@mui/material/Checkbox";
import StatusBar from "../../components/parts/StatusBar";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PartsListItemStatus from "./PartsListItemStatus";
import styles from './PartsList.module.css';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
export default function PartsListItem({part, onToggleStatusLine}) {
    return (
        <Fragment>
            <TableRow onClick={() => onToggleStatusLine(part.id)}>
                <TableCell  className={styles.select}>
                    <Checkbox
                        color="primary"
                    />
                </TableCell>
                    
                <TableCell>
                    {part.mark.toUpperCase()}
                </TableCell>
                <TableCell>
                    <img src="/pdf.svg" alt={part.mark.toUpperCase()} height="24px"/>
                </TableCell>
                <TableCell>{part.materialType && part.materialType.name}</TableCell>
                <TableCell>{part.material && part.material.code}</TableCell>
                <TableCell>
                    <StatusBar/>
                </TableCell>
                <TableCell>{part.qty}</TableCell>
                <TableCell>{part.length} mm</TableCell>
                <TableCell>{part.weight} kg</TableCell>
                <TableCell>
                    <IconButton aria-controls="simple-menu" aria-haspopup="true" size={"small"}>
                        <MoreVertIcon/>
                    </IconButton>
                </TableCell>
            </TableRow>
            {part.statusLineOpen && <PartsListItemStatus partStatus={part.status} />}
        </Fragment>
)
}