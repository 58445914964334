import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";
import PageLoadingIndicator from "../../components/ui/PageLoadingIndicator";
import Button from "@mui/material/Button";
import {snackbarService} from "../../components/ui/snackbar/SnackbarService";
import {TasksService} from "../../api/ApiService";
import TaskStatusSelect from "../../components/TaskStatusSelect";
import ProjectSelect from "../../components/ProjectSelect";

const tasksService = new TasksService();

export default function CreateTaskDialog({open, onClose}) {
    let [loading, setLoading] = useState(false);
    let [name, setName] = useState('');
    let [status, setStatus] = useState('');
    let [project, setProject] = useState(null);
    let [errors, setErrors] = useState([]);
    let [error, setError] = useState(false);
    
    const hasError = (field) => field && field.length > 0;

    const handleClose = () => {
        onClose(false);
    }

    const handleSave = async () => {
        setError(false);
        setErrors([]);
        setLoading(true);
        try {
            let result = await tasksService.createTask({
                name: name,
                projectId: project.id,
                statusId: status
            });
            snackbarService.showSnackbar(`Task created`, 'success');
            onClose(true, result);
        } catch(ex) {
            console.log(ex);
            if(ex.status === 400) {
                setError(true);
                setErrors(ex.response.errors);
            }
            snackbarService.showSnackbar(`There was an error creating the task`, 'error');
        } finally {
            setLoading(false);
        }
    }
    
    const handleStatusChange = (value) => {
        console.log(value);
        setStatus(value);
    }
    
    const handleNameChange = (event) => setName(event.target.value);
    
    const handleProjectChange = (value) => {
        setProject(value);
    }
    return (
        <>
            <PageLoadingIndicator loading={loading}/>
            <Dialog open={open} onClose={onClose} maxWidth={"md"} fullWidth={true}>
                <DialogTitle>New Task</DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        <TextField
                            error={hasError(errors["Name"])}
                            helperText={hasError(errors["Name"]) ? errors["Name"]?.join(', ') : null}
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Name"
                            type="string"
                            fullWidth
                            size={"small"}
                            value={name}
                            onChange={handleNameChange}
                        />
                        <ProjectSelect project={project} onChange={handleProjectChange} error={hasError(errors["ProjectId"])} helperText={errors["ProjectId"]}/>
                        <TaskStatusSelect onStatusChange={handleStatusChange} taskStatusId={status} />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button onClick={() => handleSave()}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}