import React from 'react';

const classes = {
    root: {
        width: '100%',
        minWidth: '150px',
        borderRadius: '8px',
        overflow: 'hidden'
    }
}

export default function StatusBar({statuses, total}) {
    statuses = [
        { count: 5, colour: '#B794F4', status: 'Approved'},
        { count: 10, colour: '#63B3ED', status: 'Fabricated'},
        { count: 15, colour: '#4FD1C5', status: 'On Site'},
    ]
    total = 30;
    return (
        <div style={classes.root}>
        {statuses.map(status => (
            <StatusBarItem status={status} total={total} key={status.status}/>
            ))}
        </div>
    )
}

function StatusBarItem({status, total}) {
    let width = (status.count / total * 100) + '%';
    let styles = {
        width: width,
        backgroundColor: status.colour,
        float: 'left',
        minWidth: width,
        height: '16px'
    };
    return (
        <div style={styles}/>
    )
}