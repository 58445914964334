import React, {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link} from "@mui/material";
import Typography from "@mui/material/Typography";
import PartsList from "./PartsList";
import Button from "@mui/material/Button";
import {ProjectPartsService, ProjectsService} from "../../api/ApiService";

let projectService = new ProjectsService();

export default function PartsPages() {
    let { projectId } = useParams();
    let [parts, setParts] = useState([]);
    let importFileRef = React.createRef();
    let [project, setProject] = useState({ name: ''});
    useEffect(() => {
        async function loadProject() {
            let projectRequest = await projectService.getProject(projectId);
            setProject(projectRequest);
        }
        loadProject();
    }, [projectId]);
    
    const fetchParts = useCallback(async () => {
        const response = await fetch(`/api/projects/${projectId}/parts`);
        let data = await response.json();
        data = data.map(i => {
            i.status = [
                { statusCode: 'DRAFT', qty: 10 },
                { statusCode: 'READY TO CUT', qty: 15 },
                { statusCode: 'FABRICATED', qty: 5 },
                { statusCode: 'COMPLETE', qty: 2 },
            ];
            return i;
        });
        setParts(data);
    }, [projectId]);
    
    useEffect(() => {
        fetchParts();
    }, [fetchParts, projectId]);
    
    let handleImportParts = () => {
        importFileRef.current.click();
    }
    
    let handleImportPartsFile = async (event) => {
        let partsService = new ProjectPartsService();
        await partsService.import(projectId, {data: event.target.files[0], fileName: event.target.files[0].name});
        await fetchParts();
    }
    
    let handleToggleStatusLine = (id) => {
        setParts(prevState => {
                return prevState.map(i => {
                    if(i.id === id) i.statusLineOpen = !i.statusLineOpen;
                    return i;
                });
            }
        )
    }
    
    return (
        <Grid container>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <RouterLink color="inherit" to="/">
                        Home
                    </RouterLink>
                    <RouterLink color="inherit" to={"/projects"}>
                        Projects
                    </RouterLink>
                    <RouterLink color="inherit" to={`/projects/${projectId}`}>
                        JB{project.number} - {project.name}
                    </RouterLink>
                    <Typography color="textPrimary">Parts</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Button style={{float: 'right'}}  variant="contained" color="primary" onClick={handleImportParts}>Import Parts</Button>
                <Typography variant="h6">{'RU Data 66 / Priority 9 - Awning'.toUpperCase()}</Typography>
                <Typography variant="h5">PARTS</Typography>
            </Grid>
            <Grid item xs={12}>
                <PartsList parts={parts} onToggleStatusLine={handleToggleStatusLine}/>
            </Grid>
            <input type="file" ref={importFileRef} hidden onChange={handleImportPartsFile} />
        </Grid>
    )
}

