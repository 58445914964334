import React, {useEffect, useState} from 'react';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ProjectsService } from "../api/ApiService.ts";
import {Search} from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";

const useStyles = makeStyles({
    tableRow: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
});

export default function ProjectsPage(props)
{
    let [searchTerm, setSearchTerm] = useState('');
    let navigate = useNavigate();
    let [projects, setProjects] = useState([]);
    useEffect(() => {
        const fetchProjects = async() => {
            const projectService = new ProjectsService();
            let results = await projectService.getProjects(searchTerm);
            setProjects(results.projects);
        };
        fetchProjects();
    }, [searchTerm]);

    let navigateToProjectPage = (projectId) => {
        navigate(projectId);
    };

    const classes = useStyles();

    let getProjectAddressString = (address) => {
        let formattedAddress = '';
        if(address) {
            if(address.line1) formattedAddress = address.line1 + ', ';
            if(address.line2) formattedAddress = formattedAddress + address.line2 + ', ';
            if(address.suburb) formattedAddress = formattedAddress + address.suburb;
        }
        return formattedAddress;
    };

    return (
        <Grid container>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link component={RouterLink} color="inherit" to={"/"}>
                        Home
                    </Link>
                    <Typography color="textPrimary">Projects</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Button component={RouterLink} style={{float: 'right'}}  variant="contained" color="primary" to="/projects/create">Create Project</Button>
                <Typography variant={"h5"}>Projects</Typography>
            </Grid>
            <Grid item xs={12}  style={{'paddingTop': 16}}>
                <TextField size="small" InputProps={{endAdornment: <InputAdornment position="end">
                        <Search
                            aria-label="search"
                            edge="end"
                        >
                        </Search>
                    </InputAdornment>}} value={searchTerm} onChange={e => setSearchTerm(e.target.value)} fullWidth={true} />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant={"button"}>Number</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Name</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Address</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Customer</Typography></TableCell>
                            <TableCell><Typography variant={"button"}>Status</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map(project =>
                            <TableRow key={project.id} hover={true} onClick={() => navigateToProjectPage(project.id)} className={classes.tableRow}>
                                <TableCell>{project.number}</TableCell>
                                <TableCell>{project.name}</TableCell>
                                <TableCell>{getProjectAddressString(project.address)}</TableCell>
                                <TableCell>{project.customer?.name}</TableCell>
                                <TableCell>{project.projectStatus?.name}</TableCell>
                            </TableRow>)}
                    </TableBody>

                </Table>
            </Grid>
        </Grid>

    )
}