import React, {Fragment, useEffect, useState} from 'react';
import {Link as RouterLink, useParams} from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Grid from "@mui/material/Grid";
import {Breadcrumbs, Menu, MenuItem, TableCell, Tooltip} from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import {ProjectFileDirectoriesService, ProjectFilesService, ProjectsService} from "../../api/ApiService";
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import Button from "@mui/material/Button";
import CreateDirectoryDialog from "./CreateDirectoryDialog";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import makeStyles from '@mui/styles/makeStyles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import MoveDialog from "./MoveDialog";

const filesService = new ProjectFilesService();
const directoryService = new ProjectFileDirectoriesService();
export const ProjectFilesPage = () => {
    let { projectId } = useParams();

    let [project, setProject] = useState({});
    let [directories, setDirectories] = useState([]);
    let [currentDirectoryId, setCurrentDirectoryId] = useState(null);
    let [currentDirectoryFiles, setCurrentDirectoryFiles] = useState([]);    
    let [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
    let [createDirectoryDialogOpen, setCreateDirectoryDialogOpen] = useState(false);
    let [selectAllToggle, setSelectAllToggle] = useState(false);
    let [moveDialogOpen, setMoveDialogOpen] = useState(false);
    
    useEffect(() => {
        const loadData = async() => {
            let projectRequest = new ProjectsService().getProject(projectId);
            let directoriesRequest = directoryService.getDirectories(projectId);
            let filesRequest = filesService.getFiles(projectId);
            let all = await Promise.all([projectRequest, directoriesRequest, filesRequest]);
            setProject(all[0]);
            setDirectories(all[1].directories.map(i => {
                i.selected = false;
                return i;
            }));
            setCurrentDirectoryFiles(all[2].map(i => {
                i.selected = false;
                return i;
            }));
        };
        
        loadData();
    }, [projectId]);
    
    let handleChangeDirectory = async (directoryId) => {
        if(directoryId !== currentDirectoryId) {
            setDirectories(prevState => {
                return prevState.map(i => {
                    i.selected = false;
                    return i;
                })
            })
        }
        setCurrentDirectoryId(directoryId);
        let response = await filesService.getFiles(projectId, directoryId);
        
        function stripExcess(list) {
            if(!list || list.length === 0) return;
            //remove file extensions
            let listNoExtensions = list.map(i => {
                return i.substr(0, i.lastIndexOf('.'));
            });
            
            let shortestStringLength = list[0].length;
            let longestStringLength = 0;

            listNoExtensions.forEach(i => {
                if(i.length < shortestStringLength) shortestStringLength = i.length;
                if(i.length > longestStringLength)  longestStringLength = i.length;
            });
            
            function charToStrip(l) 
            {
                let charactersToStripAtStart = 0;
                let same = true;
                for(let i = 0; i < shortestStringLength && same === true; i++) {
                    let character = l[0][i];
                    for(let j = 1; j < l.length; j++) {
                        if(character !== l[j][i]) {
                            same = false;
                            break;
                        }
                    }
                    if(same === true) charactersToStripAtStart += 1;
                }
                return charactersToStripAtStart;
            }
            
            let charToStringAtStart = charToStrip(listNoExtensions);
            let charToStripAtEnd = charToStrip(listNoExtensions.map(i => i.split("").reverse().join("")));
            
            let listRemovedLastChars = listNoExtensions.map(i => i.split("").reverse().join("")).map(i => i.substr(charToStripAtEnd)).map(i => i.split("").reverse().join(""));
            return listRemovedLastChars.map(i => i.substr(charToStringAtStart));
        }
        
        let shortFileNames = stripExcess(response.map(i => i.fileName));
        
        response = response.map((i, j) => {
            i.shortFileName = shortFileNames[j];
            return i;
        });
        
        setCurrentDirectoryFiles(response.map(i => {
            i.selected = false;
            return i;
        }));
        
        console.log(stripExcess(response.map(i => i.fileName)));
        
        setSelectAllToggle(false);

    };
    
    let parentDirectoryId = null;
    let currentDirectory = directories.filter(i => i.id === currentDirectoryId);
    if(currentDirectory.length === 1) {
        parentDirectoryId = currentDirectory[0].parentId;
        currentDirectoryId = currentDirectory[0].id;
    }

    let handleFileSelected = (fileId) => {
        setCurrentDirectoryFiles(prevState => {
            return prevState.map(i => {
                if(i.id === fileId) i.selected = !i.selected;
                return i;
            });      
            }
        )
    };

    let handleDirectorySelected = (directoryId) => {
        setDirectories(prevState => {
                return prevState.map(i => {
                    if(i.id === directoryId) i.selected = !i.selected;
                    return i;
                });
            }
        )
    };
    
    let handleSelectAllToggle = () => {
        if(!selectAllToggle) {
            setCurrentDirectoryFiles(prevState => {
                    return prevState.map(i => {
                        i.selected = true;
                        return i;
                    });
                }
            )

            setDirectories(prevState => {
                    return prevState.map(i => {
                        i.selected = true;
                        return i;
                    });
                }
            )
            
            setSelectAllToggle(true);
        } else {
            setCurrentDirectoryFiles(prevState => {
                    return prevState.map(i => {
                        i.selected = false;
                        return i;
                    });
                }
            )

            setDirectories(prevState => {
                    return prevState.map(i => {
                        i.selected = false;
                        return i;
                    });
                }
            )

            setSelectAllToggle(false);
        }
        
    }
    
    let handleRefresh = () => {
        handleChangeDirectory(currentDirectoryId);
        refreshDirectories();
    };
    
    let handleDelete = () => {
        setDeleteConfirmationDialogOpen(true);
    }
    
    let handleDeleteConfirmationClosed = (deleted) => {
        setDeleteConfirmationDialogOpen(false);
        if(!deleted) return;
        handleRefresh();
    };

    let refreshDirectories = async () => {
        let directoriesRequest = await directoryService.getDirectories(projectId);
        setDirectories(directoriesRequest.directories.map(i => { i.selected = false; return i}))
    };

    let handleNavigateUpDirectory = async () => {
        handleChangeDirectory(parentDirectoryId);
    };
    
    let handleUpload = async (files) => {
        await Promise.all(Array.from(files).map(f => {
            return filesService.uploadFile(projectId, currentDirectoryId, true, {data: f, fileName: f.name});
        }));
        handleRefresh();
    };

    let handleCreateDirectory = () => {
        setCreateDirectoryDialogOpen(true);
    }

    let handleCreateDirectoryClosed = () => {
        setCreateDirectoryDialogOpen(false);
        handleRefresh();
    };
    
    const handleMove = () => {
        setMoveDialogOpen(true);
    }

    const handleMoveClosed = () => {
        setMoveDialogOpen(false);
        handleRefresh();
    }
    
    const handleSetFabDirectory = async (directoryId) => {
        let pfd = new ProjectFileDirectoriesService();
        await pfd.setCurrentFabricationDrawingDirectory(projectId, directoryId);
        setProject(prevState => {
            return { ...prevState, currentFabricationDrawingDirectoryId: directoryId};
        });
        
    }
    
    return ( 
        <Grid container>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Breadcrumbs aria-label="breadcrumb">
                    <RouterLink color="inherit" to="/">
                        Home
                    </RouterLink>
                    <RouterLink color="inherit" to={"/projects"}>
                        Projects
                    </RouterLink>
                    <RouterLink color="inherit" to={`/projects/${projectId}`}>
                        JB{project.number} - {project.name}
                    </RouterLink>
                    <Typography color="textPrimary">Files</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid item xs={12} style={{'paddingTop': 16}}>
                <Typography variant="h5">{project.name}</Typography>
                <Typography variant="subtitle1">FILES</Typography>
            </Grid>
            <Grid item xs={12}>
                <FileActionPanel
                    onRefresh={handleRefresh} 
                    onDelete={handleDelete}
                    onNavigateUpDirectory={handleNavigateUpDirectory}
                    onUpload={handleUpload}
                    onCreateDirectory={handleCreateDirectory}
                    onMove={handleMove}
                />
            </Grid>
            <Grid item md={3}>
                <DirectoryPanel directories={directories} selectedDirectory={currentDirectoryId} onChangeDirectory={handleChangeDirectory} project={project}/>
            </Grid>
            <Grid item md={9}>
                <FilePanel 
                    directories={directories.filter(i => i.parentId === currentDirectoryId)}
                    files={currentDirectoryFiles}
                    onFileSelected={handleFileSelected}
                    onDirectorySelected={handleDirectorySelected}
                    onDirectoryChange={handleChangeDirectory}
                    onUpload={handleUpload}
                    onSelectAllToggle={handleSelectAllToggle}
                    onSetFabDirectory={handleSetFabDirectory}
                    selectAllToggle={selectAllToggle}
                    project={project}
                />
            </Grid>
            <DeleteConfirmationDialog 
                directories={directories.filter(i => i.selected)}
                files={currentDirectoryFiles.filter(i => i.selected)}
                isOpen={deleteConfirmationDialogOpen}
                projectId={projectId}
                onClose={handleDeleteConfirmationClosed}
            />
            <CreateDirectoryDialog
                directoryId={currentDirectoryId}
                isOpen={createDirectoryDialogOpen}
                projectId={projectId}
                onClose={handleCreateDirectoryClosed}
            />
            <MoveDialog
                projectId={projectId}
                onClose={handleMoveClosed}
                directories={directories.filter(i => i.selected)}
                files={currentDirectoryFiles.filter(i => i.selected)}
                isOpen={moveDialogOpen}
                />
        </Grid>
    )
};

const useStylesFileActionPanel = makeStyles({
    root: {
        display: 'flex',
        'justify-content': 'flex-end'
    }
})

function FileActionPanel({onRefresh, onDelete, onNavigateUpDirectory, onUpload, onCreateDirectory, onMove}) 
{
    const classes = useStylesFileActionPanel();
    
    let fileInput = React.createRef();

    let handleUploadClick = () => {
        fileInput.current.click()
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMove = () => {
        handleClose();
        onMove();
    }

    const handleDelete = () => {
        handleClose();
        onDelete();
    }

    const handleCreateDirectory = () => {
        handleClose();
        onCreateDirectory();
    }
    
    return (
        <Grid className={classes.root}>
            {/*<Tooltip title="Create a directory">*/}
            {/*    <IconButton aria-label={"create directory"} onClick={onCreateDirectory}><CreateNewFolderIcon/></IconButton>*/}
            {/*</Tooltip>*/}
            <Tooltip title="Navigate up a directory">
                <IconButton
                    aria-label={"navigate up a directory"}
                    onClick={onNavigateUpDirectory}
                    size="large"><ArrowUpwardIcon/></IconButton>
            </Tooltip>
            <Tooltip title="Refresh">
                <IconButton aria-label={"refresh"} onClick={onRefresh} size="large"><RefreshIcon/></IconButton>
            </Tooltip>
            {/*<Tooltip title="Delete selected files/folders">*/}
            {/*    <IconButton aria-label={"delete"} onClick={onDelete}><DeleteIcon/></IconButton>*/}
            {/*</Tooltip>*/}
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                More Actions
            </Button>
                <Button variant={"contained"} color={"primary"} aria-label={"upload"} onClick={handleUploadClick} startIcon={<CloudUploadIcon/>}>Upload</Button>
                <input ref={fileInput} type="file" name="file" multiple onChange={(e) => onUpload(e.target.files)} hidden/>
            
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleMove}>Move Selected</MenuItem>
                <MenuItem onClick={handleDelete}>Delete Selected</MenuItem>
                <MenuItem onClick={handleCreateDirectory}>Create Directory</MenuItem>
            </Menu>
        </Grid>
    );
}

const useStyles = makeStyles({
    root: {
        tableLayout: 'fixed',
        width: '100%',
        
        '&:hover': {
            cursor: 'pointer'
        }
    },
    
    cells: {
        whiteSpace: "nowrap",
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
});

function FilePanel({directories, files, onFileSelected, onDirectorySelected, onDirectoryChange, project, onSelectAllToggle, selectAllToggle, onSetFabDirectory}) {
    return (
        <Fragment>
        <Table dense={true} style={{maxWidth: '100%'}}>
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox onClick={onSelectAllToggle} checked={selectAllToggle}/>
                    </TableCell>
                    <TableCell  padding="checkbox"></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>By</TableCell>
                    <TableCell>Modified</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {directories.map(d => <FilePanelDirectoryLine key={d.id} currentFabDirectoryId={project.currentFabricationDrawingDirectoryId} directory={d} onDirectorySelected={() => onDirectorySelected(d.id)} onDirectoryChange={() => onDirectoryChange(d.id)} onSetFabDirectory={onSetFabDirectory}/>)}
                {files.map(f => <FilePanelFileLine key={f.id} file={f} onFileSelected={() => onFileSelected(f.id)} project={project} />)}
            </TableBody>
        </Table>
        </Fragment>
    )
}


function FilePanelDirectoryLine({directory, onDirectorySelected, onDirectoryChange, onSetFabDirectory, currentFabDirectoryId}) {
    const classes = useStyles();
    let isCurrentFabDirectory = false;
    if(currentFabDirectoryId) {
        if(currentFabDirectoryId === directory.id) isCurrentFabDirectory = true;
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const handleDirectoryClick = () => {
        if(!!anchorEl) return;
        onDirectoryChange(directory.id);
    } 
    
    return (
        <TableRow onClick={handleDirectoryClick} hover={true} className={classes.root}>
            <TableCell  padding="checkbox">
                <Checkbox checked={directory.selected} onClick={(e) => { e.stopPropagation()}} onChange={onDirectorySelected}/>
            </TableCell>
            <TableCell ><FolderIcon/></TableCell>
            <TableCell className={classes.cells}>{directory.name} &nbsp; {isCurrentFabDirectory ? <StarIcon fontSize={"small"} /> : ''}</TableCell>
            <TableCell className={classes.cells}></TableCell>
            <TableCell className={classes.cells}>Robert O'Reilly</TableCell>
            <TableCell className={classes.cells}>1 min ago</TableCell>
            <TableCell style={{padding: 0}}>
            </TableCell>
        </TableRow>
    );
}

function FilePanelFileLine({file, onFileSelected, project}) {
    let periodPoint = file.fileName.lastIndexOf('.');
    let fileExtension = file.fileName.substr(periodPoint + 1, file.fileName.length - periodPoint + 1).toUpperCase();
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    
    const classes = useStyles();
    const link = "/api/projects/" + project.id + "/files/download/" + file.id;
    return (
        <TableRow className={classes.root} hover={true} onClick={() => Boolean(anchorEl) ? null : window.open(link, '_blank')}>
            <TableCell  padding="checkbox"><Checkbox checked={file.selected} onChange={onFileSelected} onClick={e => e.stopPropagation()}/></TableCell>
            <TableCell ><DescriptionOutlinedIcon/></TableCell>
            <TableCell className={classes.cells}>{file.fileName}</TableCell>
            <TableCell className={classes.cells}>{fileExtension}</TableCell>
            <TableCell className={classes.cells}>Robert O'Reilly</TableCell>
            <TableCell className={classes.cells}>1 min ago</TableCell>
            <TableCell style={{padding: 0}}>
            </TableCell>
        </TableRow>
    );
}

function DirectoryPanelLine({ directory, selectedDirectory, onChangeDirectory, currentFabDirectoryId}) {    
    let isCurrentFabDirectory = false;
    if(currentFabDirectoryId) {
        if(currentFabDirectoryId === directory.id) isCurrentFabDirectory = true;
    }
    return (
        <Fragment>
            <ListItem button onClick={() => onChangeDirectory(directory.id)} selected={directory.id === selectedDirectory} style={{paddingLeft: 8*directory.depth}}>
                <ListItemIcon>
                    <FolderIcon/>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{style: {whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden"}}}>{directory.name} &nbsp;
                
                    {isCurrentFabDirectory ? <StarIcon fontSize={"small"} /> : ''}
                
                </ListItemText>
            </ListItem>
        </Fragment>
    )
}

function DirectoryPanel({ directories, selectedDirectory, onChangeDirectory, project}) {
    
    let homeDirectory = {
        id: null,
        name: project.name + ' Files',
        parentId: null,
        depth: 0
    };

    let dirs = [homeDirectory, ...sortHeirarchyWithDepth(directories)];
    let dirElements = dirs.map(d => <DirectoryPanelLine currentFabDirectoryId={project.currentFabricationDrawingDirectoryId} directory={d} key={d.id} onChangeDirectory={onChangeDirectory} selectedDirectory={selectedDirectory}/>)
    return (
        <List component="nav" dense={true} style={{maxWidth: 300}}>
            {dirElements}
        </List>
    )
}

function sortHeirarchyWithDepth(items, startDepth = 1) {
    let sorted = [];
    
    function addDepth(arr, depth = 0) {
        arr.forEach(obj => {
            obj.depth = depth
            sorted.push(obj);
            addDepth(items.filter(i => i.parentId === obj.id), depth + 1)
        })
    }
    
    addDepth(items.filter(i => i.parentId === null), startDepth);
    
    return sorted;
}
