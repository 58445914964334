import React, {useEffect, useState} from 'react';
import {GetTasksRequest, TasksService} from "../../api/ApiService";
import {useParams} from "react-router";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CreateTaskDialog from "./CreateTaskDialog";

const tasksService = new TasksService();

export default function ProjectTasksList() {
    let { projectId } = useParams();
    let [createDialogOpen, setCreateDialogOpen] = useState(false);
    
    let [tasks, setTasks] = useState([]);
    useEffect(() => {
        const loadTasks = async () => {
            let response = await tasksService.getTasks(projectId);
            setTasks(response.tasks);
        }
        loadTasks();
    }, [projectId]);
    
    const openCreateTaskDialog = () => {
        setCreateDialogOpen(true);
    }
    
    const handleCreateTaskDialogClose = () => {
        
    }
    
    return (
        <div>
            <Typography variant={"h5"}>Tasks</Typography>
            <Button onClick={openCreateTaskDialog}>New Task</Button>
            {tasks.map(t => <TaskListItem task={t}/>)}
            <CreateTaskDialog open={createDialogOpen} onClose={handleCreateTaskDialogClose} />
        </div>
    )
}

function TaskListItem({ task }) {
    return (<div>
        {task.title}
    </div>)
}